import styled from 'styled-components';
import { shade } from 'polished';

interface ScheduleLineProps {
  etapa: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50vh;
  height: calc(100vh - 8rem);
  margin: 4rem auto;
  overflow-y: auto;

  @media (max-width: 820px) {
    margin: 0rem auto 2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  height: calc(100% - 150px);
  padding: 0 2rem;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 1rem;
  width: calc(100% - 3rem);
  padding-bottom: 2rem;
`;

export const SubHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: 0.5rem;
  margin-top: 2rem;
  width: 100%;
  form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-center;
    width: 90%;
    gap: 0.5rem;
  }
  @media (max-width: 820px) {
    > form {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }
`;

export const ScheduleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid var(--purple02);
  width: 71rem;

  @media (max-width: 820px) {
    width: 100%;
  }

  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--purple02);
    color: white;
    width: 100%;
    padding: 0.5rem;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const ScheduleLine = styled.div<ScheduleLineProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 2px solid var(--purple02);
  background-color: ${props =>
    props.etapa === 'Oitavas' || props.etapa === 'Semi'
      ? '#F9F7FC'
      : 'var(--color-background)'};

  &:hover {
    cursor: pointer;
    background-color: ${shade(0.01, '#E5DEF0')};
  }

  @media (max-width: 820px) {
    display: grid;
    width: 100%;
    grid-template-columns: 3rem repeat(4, 1fr) 3rem;
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      'etapa match match match match score'
      'place place place place place place'
      'date date date hour hour hour'
      'rep rep rep rep rep rep';

    > .etapa {
      width: 3rem !important;
      writing-mode: vertical-rl;
      grid-area: etapa;
      border-bottom: 1px solid var(--purple02) !important;
    }
    > .score {
      width: 3rem !important;
      grid-area: score;
      border-right: 0 !important;
      border-bottom: 1px solid var(--purple02) !important;
    }
    > .match {
      width: 100% !important;
      grid-area: match;
      border-bottom: 1px solid var(--purple02) !important;
    }
    > .place {
      width: 100% !important;
      grid-area: place;
      border-right: 0 !important;
      border-bottom: 1px solid var(--purple02) !important;
      ::before {
        content: 'Local:';
        margin-right: 0.5rem;
      }
    }
    > .rep {
      width: 100% !important;
      grid-area: rep;
      border-right: 0 !important;

      > div {
        ::before {
          content: 'Rep:';
          margin-right: 0.5rem;
        }
      }
    }
    > #date {
      width: 100% !important;
      grid-area: date;
      border-bottom: 1px solid var(--purple02) !important;
      ::before {
        content: 'Data:';
        margin-right: 0.5rem;
      }
    }
    > #hour {
      width: 100% !important;
      grid-area: hour;
      border-right: 0 !important;
      border-bottom: 1px solid var(--purple02) !important;
      ::before {
        content: 'Horario:';
        margin-right: 0.5rem;
      }
    }
  }
  > .etapa {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 100%;
    border-right: 1px solid var(--purple02);
  }

  > .match {
    display: flex;
    flex-direction: column;
    width: 17rem;
    border-right: 1px solid var(--purple02);
    height: 100%;

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.5rem 1rem;
      height: 100%;

      img {
        height: 2rem;
        margin-right: 0.5rem;
      }
      &:first-child {
        border-bottom: 1px solid var(--purple02);
      }
    }
  }

  > .score {
    display: flex;
    flex-direction: column;
    width: 3rem;
    border-right: 1px solid var(--purple02);
    height: 100%;

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.5rem 1rem;
      height: 100%;

      &:first-child {
        border-bottom: 1px solid var(--purple02);
      }
    }
  }
  > .rep {
    display: flex;
    flex-direction: column;
    width: 17rem;

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.5rem 1rem;

      img {
        height: 2rem;
        margin-right: 0.5rem;
      }
      &:first-child {
        border-bottom: 1px solid var(--purple02);
      }
    }
  }

  > .date {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 100%;
    border-right: 1px solid var(--purple02);
  }
  > .hour {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 100%;
    border-right: 1px solid var(--purple02);
  }
  > .place {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;
    height: 100%;
    border-right: 1px solid var(--purple02);
    text-align: center;
    white-space: pre-wrap;
  }
`;

export const ScheduleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 3rem;
  border-bottom: 2px solid var(--purple02);
  background-color: #f2eff8;

  @media (max-width: 820px) {
    display: none;
  }

  > .etapa {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 100%;
    border-right: 1px solid var(--purple02);
  }

  > .match {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20rem;
    height: 100%;
    border-right: 1px solid var(--purple02);
  }
  > .rep {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 17rem;
  }

  > .date {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 100%;
    border-right: 1px solid var(--purple02);
  }
  > .hour {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 100%;
    border-right: 1px solid var(--purple02);
  }
  > .place {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;
    height: 100%;
    border-right: 1px solid var(--purple02);
    text-align: center;
  }
`;

export const IndBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid var(--purple02);
  width: 71rem;

  @media (max-width: 820px) {
    width: 100%;
  }

  
`;
export const IndHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--purple02);
    color: white;
    width: 100%;
    padding: 0.5rem;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid var(--purple02);
      background-color: #F9F7FC;
    }

    > .mod{
      width: 30%;
      padding: 0.5rem;
      border-right: 1px solid var(--purple02);
    }

    > .date{
      width: 20%;
      padding: 0.5rem;
      border-right: 1px solid var(--purple02);
    }

    > .hour{
      width: 20%;
      padding: 0.5rem;
      border-right: 1px solid var(--purple02);
    }

    > .place{
      width: 30%;
      padding: 0.5rem;
    }
  }
`;
export const IndLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid var(--purple02);
  background-color: #F9F7FC;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 6rem;
    text-align: center;
  }

  > .mod{
    width: 30%;
    padding: 0.5rem;
    border-right: 1px solid var(--purple02);
  }

  > .date{
    width: 20%;
    padding: 0.5rem;
    border-right: 1px solid var(--purple02);
  }

  > .hour{
    width: 20%;
    padding: 0.5rem;
    border-right: 1px solid var(--purple02);
  }

  > .place{
    width: 30%;
    padding: 0.5rem;
  }
`;