import { useCallback, useEffect, useMemo, useState } from 'react';
import { FiEdit, FiPlus, FiTrash2 } from 'react-icons/fi';
import { Column } from 'react-table'
import { ModalCaster } from './components/ModalCaster';
import { ModalCasterEdit } from './components/ModalCasterEdit';
import { ModalWaring } from '../../components/ModalWaring';
import Table from '../../components/Table';
import { useAuth } from '../../hooks/auth';
import { useData } from '../../hooks/context';
import { ICaster } from '../../types';

import {
  Container,
  IconContainer,
  Header,
  AddNew
} from './styles';
import { useNavigate } from 'react-router-dom';

function Caster() {

  const navigate = useNavigate()
  const widthScreen = window.innerWidth
  const [showCasterModal, setShowCasterModal] = useState(false);
  const [showCasterModalEdit, setShowCasterModalEdit] = useState(false);
  const [showCasterModalDelete, setShowCasterModalDelete] = useState(false);
  const [editCaster, setEditCaster] = useState({} as ICaster);
  const [deleteCaster, setDeleteCaster] = useState('');
  const { admin } = useAuth();
  const isAdmin = useMemo(() => admin?.responsibility === 'admin', [admin])
  const { useCaster } = useData();
  const { casters } = useCaster;


  useEffect(() => {
    if(!isAdmin){
      navigate('/')
    }
  }, [isAdmin, navigate])
  const toggleShowModalOpenEdit = useCallback(() => {
    setShowCasterModalEdit(!showCasterModalEdit)
  }, [showCasterModalEdit]);

  const toggleShowModalOpenDelete = useCallback(() => {
    setShowCasterModalDelete(!showCasterModalDelete)
  }, [showCasterModalDelete]);

  const handleEditCaster = useCallback((casterEdit: ICaster) => {
    toggleShowModalOpenEdit()
    setEditCaster(casterEdit)
  }, [toggleShowModalOpenEdit])

  const handleDeleteCaster = useCallback((adminsId: string) => {
    toggleShowModalOpenDelete()
    setDeleteCaster(adminsId)
  }, [toggleShowModalOpenDelete])

  const delCaster = useCallback(() => {
    useCaster.removeCaster(deleteCaster)
    toggleShowModalOpenDelete()
  }, [toggleShowModalOpenDelete, useCaster, deleteCaster])

  const columns: Column<any>[] = useMemo(() => [
    {
      Header: 'Nome',
      Cell: ({ row }) => {
        return (
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {row.original.name}
          </div>
        )
      },
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      Cell: ({ row }) => {
        return (
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {row.original.email}
          </div>
        )
      },
    },
    {
      Header: 'Opções',
      Cell: ({ row }) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <IconContainer type='edit' onClick={() => handleEditCaster(row.original)}>
              <FiEdit />
              {widthScreen <= 820 && 'Editar'}
            </IconContainer>
            <IconContainer type='del' onClick={() => handleDeleteCaster(row.original.id)}>
              <FiTrash2 />
              {widthScreen <= 820 && 'Deletar'}
            </IconContainer>
          </div>
          
        )
      },
    },
  ], [widthScreen, handleEditCaster, handleDeleteCaster]);

  const toggleShowModalOpen = useCallback(() => {
    setShowCasterModal(!showCasterModal)
  }, [showCasterModal]);

  return (
    <Container>
      <Header>
        <h1>Casters</h1>
        {isAdmin && (
          <AddNew onClick={toggleShowModalOpen}>
            <FiPlus />
            New
          </AddNew>
        )}
      </Header>
      <Table
        columns={columns}
        data={casters}
      />
      <ModalCaster
        showModalOpen={showCasterModal}
        toggleShowModalOpen={toggleShowModalOpen}
      />
      <ModalCasterEdit
        showModalOpen={showCasterModalEdit}
        toggleShowModalOpen={toggleShowModalOpenEdit}
        caster={editCaster}
      />
      <ModalWaring
        showModalOpen={showCasterModalDelete}
        toggleShowModalOpen={toggleShowModalOpenDelete}
        title="Deletar caster"
        message='Tem certeza que deseja deletar esse caster?'
        textLeftButton='Cancelar'
        textRightButton='Deletar'
        handleLeftButton={toggleShowModalOpenDelete}
        handleRightButton={delCaster}
      />
    </Container>
  );
};

export default Caster;
