import { useCallback, useMemo, useState } from 'react';
import { FiEdit, FiPlus, FiTrash2 } from 'react-icons/fi';
import { Column } from 'react-table';
import { ModalWaring } from '../../components/ModalWaring';
import Table from '../../components/Table';
import { useAuth } from '../../hooks/auth';
import { INews } from '../../types';

import { Container, IconContainer, Header, AddNew } from './styles';
import { format } from 'date-fns';
import api from '../../services/api';
import { MoonLoader } from 'react-spinners';
import { ModalNews } from './components/ModalNews';
import { ModalNewsEdit } from './components/ModalNewsEdit';
import { useQuery } from '@tanstack/react-query';
import { useEdition } from '../../hooks/edition';

function PageNews() {
  const widthScreen = window.innerWidth;
  const { admin } = useAuth();
  const isAtletica = useMemo(
    () => admin?.responsibility === 'atletica',
    [admin],
  );
  const [showNewsModal, setShowNewsModal] = useState(false);
  const [showNewsModalEdit, setShowNewsModalEdit] = useState(false);
  const [showNewsModalDelete, setShowNewsModalDelete] = useState(false);
  const [editNews, setEditNews] = useState({} as INews);
  const [deleteNews, setDeleteNews] = useState('');
  const { edition } = useEdition()

  const {
    data: news,
    isLoading,
    refetch
  } = useQuery<INews[]>(['news'], async () => {
    const { data } = await api.get(`/news/${isAtletica ? `atletica/${admin.id}` : 'admin'}`, {
      headers: {
        'x-edition': edition
      }
    });
    return data;
  }, {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  })
  
  const toggleShowModalOpenEdit = useCallback(() => {
    setShowNewsModalEdit(!showNewsModalEdit);
  }, [showNewsModalEdit]);

  const toggleShowModalOpenDelete = useCallback(() => {
    setShowNewsModalDelete(!showNewsModalDelete);
  }, [showNewsModalDelete]);

  const handleEditNews = useCallback(
    (NewsEdit: INews) => {
      toggleShowModalOpenEdit();
      setEditNews(NewsEdit);
    },
    [toggleShowModalOpenEdit],
  );

  const handleDeleteNews = useCallback(
    (NewsId: string) => {
      toggleShowModalOpenDelete();
      setDeleteNews(NewsId);
    },
    [toggleShowModalOpenDelete],
  );

  const delNews = useCallback(async () => {
    await api.delete(`/news/${isAtletica ? `atletica` : 'admin'}/${deleteNews}`);
    toggleShowModalOpenDelete();
    refetch();
  }, [deleteNews, isAtletica, refetch, toggleShowModalOpenDelete]);

  const columns: Column<any>[] = useMemo(
    () => [
            {
              Header: 'Título',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.title}
                  </div>
                );
              },
            },
            {
              Header: 'Data',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {format(new Date(row.original.created_at), 'dd/MM/yyyy')}
                  </div>
                );
              },
            },
            {
              Header: 'Opções',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IconContainer
                      type="edit"
                      onClick={() => handleEditNews(row.original)}
                    >
                      <FiEdit />
                      {widthScreen <= 820 && 'Editar'}
                    </IconContainer>
                    <IconContainer
                      type="del"
                      onClick={() => handleDeleteNews(row.original.id)}
                    >
                      <FiTrash2 />
                      {widthScreen <= 820 && 'Deletar'}
                    </IconContainer>
                  </div>
                );
              },
            },
          ],
    [handleEditNews, handleDeleteNews, widthScreen],
  );

  const toggleShowModalOpen = useCallback(() => {
    setShowNewsModal(!showNewsModal);
  }, [showNewsModal]);

  return (
    <Container>
      <Header>
        <h1>Notícias</h1>
        <AddNew onClick={toggleShowModalOpen}>
          <FiPlus />
          New
        </AddNew>
      </Header>
      {!isLoading ? (
        <Table columns={columns} data={news || []} />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            alignItems: 'center',
          }}
        >
          <MoonLoader color="#3B1467" loading speedMultiplier={0.75} />
          Carregando...
        </div>
      )}

      <ModalNews
        showModalOpen={showNewsModal}
        toggleShowModalOpen={toggleShowModalOpen}
        refetch={refetch}
        isAtletica={isAtletica}
      />
      <ModalNewsEdit
        showModalOpen={showNewsModalEdit}
        toggleShowModalOpen={toggleShowModalOpenEdit}
        news={editNews}
        isAtletica={isAtletica}
        refetch={refetch}
      />
      <ModalWaring
        showModalOpen={showNewsModalDelete}
        toggleShowModalOpen={toggleShowModalOpenDelete}
        title="Deletar festar"
        message="Tem certeza que deseja deletar essa festa?"
        textLeftButton="Cancelar"
        textRightButton="Deletar"
        handleLeftButton={toggleShowModalOpenDelete}
        handleRightButton={delNews}
      />
    </Container>
  );
}

export default PageNews;
