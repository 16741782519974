import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  background: var(--color-background);
  border-radius: 1rem;
  border: 2px dashed var(--purple02);
  padding: 1rem;
  width: 100%;
  height: 100%;
  color: var(--purple02);
  display: flex;
  align-items: center;

  & + div {
    margin-top: 0.75rem;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: var(--error);
    `}

  .loading {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    align-items: center;
    justify-content: center;
  }
  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    border-radius: 1rem;
  }

  > label {
    flex: 1;
    height: 100%;
    width: 100%;
    z-index: 3;

    &:hover {
      cursor: pointer;
    }
  }

  input {
    display: none;
  }

  > svg {
    position: absolute;
    z-index: 3;
    bottom: 1rem;
    right: 1rem;
    color: ${props => (props.isFilled ? 'var(--success)' : 'var(--purple01)')};

    &:hover {
      cursor: pointer;
    }
  }
`;

export const AlertError = styled(Tooltip)`
  height: 2rem;
  margin-left: 1.5rem;

  svg {
    margin: 0;
  }

  span {
    background: var(--error);
    color: #fff;

    &::before {
      border-color: var(--error) transparent;
    }
  }
`;