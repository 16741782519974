/* eslint-disable no-shadow */
import React, {
    createContext,
    useCallback,
    useState,
    useContext,
    ReactNode,
  } from 'react';
import api from '../services/api';
import apiWebhook from '../services/apiWebhook';
  
  interface EditionContextState {
    edition: string;
    setEdition: (edition: string) => void;
  }
  
  
  export const EditionContext = createContext<EditionContextState>(
    {} as EditionContextState,
  );
  
  interface EditionProviderProps {
    children: ReactNode;
  }
  
  export function EditionProvider({ children }: EditionProviderProps) {
    console.log(process.env)
    const jogos = ['JF', 'IM'].includes(process.env.REACT_APP_JOGOS || '') ? process.env.REACT_APP_JOGOS || "JF" : 'JF';
    const [ed, setEd] = useState<string>(localStorage.getItem('@AdminControl:edition') || (jogos === "IM" ? 'im2024A' : 'joia2023'));

    const setEdition = useCallback((edition: string) => {
        setEd(edition)
        api.defaults.headers.common['x-edition'] = edition;
        apiWebhook.defaults.headers.common['x-edition'] = edition;
        localStorage.setItem('@AdminControl:edition', edition)
    }, []);
    
    return (
      <EditionContext.Provider
        value={{ edition: ed, setEdition }}
      >
        {children}
      </EditionContext.Provider>
    );
  }
  
  export function useEdition(): EditionContextState {
    const context = useContext(EditionContext);
  
    if (!context) {
      throw new Error('useEdition must be used within an EditionProvider');
    }
    return context;
  }
  