import { useCallback, useEffect, useMemo, useState } from 'react';
import { FiArrowLeft, FiAward, FiClipboard, FiDownload, FiRepeat } from 'react-icons/fi';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useData } from '../../hooks/context';
import { IMatch, IQualifier } from '../../types';

import {
  Container,
  Header,
  AddNew,
  Content,
  Oitavas,
  Quartas,
  Semi,
  Final,
} from './styles';

import athletics from '../../assets/sports-icons/athletics.svg';
import basketball from '../../assets/sports-icons/basketball.svg';
import beachVolleyball from '../../assets/sports-icons/beach-volleyball.svg';
import football from '../../assets/sports-icons/football.svg';
import futsal from '../../assets/sports-icons/futsal.svg';
import handball from '../../assets/sports-icons/handball.svg';
import judo from '../../assets/sports-icons/judo.svg';
import swimming from '../../assets/sports-icons/swimming.svg';
import tableTennis from '../../assets/sports-icons/table-tennis.svg';
import tennis from '../../assets/sports-icons/tennis.svg';
import volleyball from '../../assets/sports-icons/volleyball.svg';
import chess from '../../assets/sports-icons/chess.svg';
import logo from '../../assets/logo_s.svg';
import MatchBox from './components/MatchBox';
import ModalMatchBox from './components/ModalMatchBox';
import ModalSorteio from './components/ModalSorteio';
import SelectModalidade from '../../components/SelectModalidade';
import ModalRanking from './components/ModalRanking';
import capitalize from '../../utils/capitalize';
import csgo from '../../assets/sports-icons/csgo.svg';
import clash from '../../assets/sports-icons/clash-royale.svg';
import fifa from '../../assets/sports-icons/fifa.svg';
import valorant from '../../assets/sports-icons/valorant.svg';
import lol from '../../assets/sports-icons/lol.svg';
import { downloadImage } from '../../utils/downloadImage';
import { toast } from 'react-toastify';

const icons = [
  [athletics, 'athletics'],
  [basketball, 'basketball'],
  [beachVolleyball, 'beachVolleyball'],
  [football, 'football'],
  [futsal, 'futsal'],
  [handball, 'handball'],
  [judo, 'judo'],
  [swimming, 'swimming'],
  [tableTennis, 'tableTennis'],
  [tennis, 'tennis'],
  [volleyball, 'volleyball'],
  [chess, 'chess'],
  [csgo, 'csgo'],
  [clash, 'clash'],
  [fifa, 'fifa'],
  [valorant, 'valorant'],
  [lol, 'lol'],
];

/* const schemaLeft = [
  {
    out1: 1,
    out2: 2,
    in: 9,
  },
  {
    out1: 3,
    out2: 4,
    in: 10,
  },
  {
    out1: 9,
    out2: 10,
    in: 13,
  },
];

const schemaRight = [
  {
    out1: 5,
    out2: 6,
    in: 11,
  },
  {
    out1: 7,
    out2: 8,
    in: 12,
  },
  {
    out1: 11,
    out2: 12,
    in: 14,
  },
];
 */
function PlayoffPage() {
  const widthScreen = window.innerWidth;
  const { admin } = useAuth();
  const isAtletica = useMemo(
    () => admin?.responsibility === 'atletica',
    [admin],
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { useQualifier } = useData();
  const { qualifiers } = useQualifier;

  const [qualifier, setQualifier] = useState({} as IQualifier);
  const [showModal, setShowModal] = useState(false);
  const [showModalSorteio, setShowModalSorteio] = useState(false);
  const [editMatch, setEditMatch] = useState({} as IMatch);
  const [showModalSelectModalidade, setShowModalSelectModalidade] =
    useState(false);
  const [showModalRanking, setShowModalRanking] = useState(false);

  const toggleShowModalRanking = useCallback(() => {
    setShowModalRanking(!showModalRanking);
  }, [showModalRanking]);

  const toggleShowModalSelectModalidade = useCallback(() => {
    setShowModalSelectModalidade(!showModalSelectModalidade);
  }, [showModalSelectModalidade]);

  const toggleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const toggleShowModalSorteio = useCallback(() => {
    setShowModalSorteio(!showModalSorteio);
  }, [showModalSorteio]);

  useEffect(() => {
    const pathname = location.pathname.split('/');
    const id = pathname[pathname.length - 1];
    if (qualifiers.length > 0) {
      const findQualifier = qualifiers.find(item => item.id === id);
      if (findQualifier) {
        setQualifier(findQualifier);
      } else {
        navigate('/qualifiers');
      }
    }
  }, [location.pathname, navigate, qualifiers]);

  function getIcons(value: string) {
    const icon = icons.find((item: any[]) => item[1] === value);
    return icon ? icon[0] : undefined;
  }

  const handleEditMatch = useCallback(
    (editMatch: IMatch) => {
      setEditMatch(editMatch);
      toggleShowModal();
    },
    [toggleShowModal],
  );

  /* const dot13out = document
    ?.getElementById('dot-13-out')
    ?.getBoundingClientRect();
  const dot14out = document
    ?.getElementById('dot-14-out')
    ?.getBoundingClientRect();
  const dot15in = document
    ?.getElementById('dot-15-in')
    ?.getBoundingClientRect();
  const dot15out = document
    ?.getElementById('dot-15-out')
    ?.getBoundingClientRect(); */

  const handleDownload = useCallback((saveAs: 'jpg' | 'pdf') => {
    const ele = document.getElementById('playoff-content');
    const el = document.getElementById('playoff-box');
    if(ele && el){
      downloadImage({
        ele: el,
        name: `${qualifier.name}-${capitalize(qualifier.type)}`,
        saveAs: saveAs,
        orientation: 'landscape',
        options: {
          width: ele.scrollWidth,
          height: ele.scrollHeight,
          onclone: (d, e) => {
            e.style.overflow = 'visible';
            const check = d.getElementById('playoff-content')
            if(check){
              check.style.overflow = 'visible'
            }
          }
        }
      })
    }
  }, [qualifier])

  const handleDownloadDoc = useCallback((doc: string | undefined, match: string) => {
    if(!doc) {
      toast.error('Partida sem súmula')
    } else {
      const link = document.createElement('a');
      link.href = doc;
      link.target = "_blank"
      link.setAttribute(
        'download',
        `${qualifier.name} ${qualifier.type} - ${match}`
      );
  
      // Append to html link element page
      document.body.appendChild(link);
  
      // Start download
      link.click();
  
      // Clean up and remove the link
      link?.parentNode?.removeChild(link);
    }
  }, [qualifier])


  return (
    <Container id='playoff-box'>
      {qualifier.id ? (
        <>
          <Header>
            <div>
              <div>
                <img src={getIcons(qualifier.icon) || logo} alt="icon" />
                <h1>{`${qualifier.name} ${capitalize(qualifier.type)}`}</h1>
              </div>
              <div id="playoff-options" data-html2canvas-ignore="true">
                {!isAtletica && (
                  <AddNew onClick={toggleShowModalSorteio}>
                    <FiClipboard />
                    {widthScreen > 820 && 'Sorteio'}
                  </AddNew>
                )}
                <AddNew onClick={toggleShowModalRanking}>
                  <FiAward />
                  {widthScreen > 820 && 'Ver classificação'}
                </AddNew>
                <AddNew onClick={toggleShowModalSelectModalidade}>
                  <FiRepeat />
                  {widthScreen > 820 && 'Selecionar outra modalidade'}
                </AddNew>
                <AddNew onClick={() => handleDownload('jpg')}>
                  <FiDownload />
                  {widthScreen > 820 && 'JPG'}
                </AddNew>
                <AddNew onClick={() => handleDownload('pdf')}>
                  <FiDownload />
                  {widthScreen > 820 && 'PDF'}
                </AddNew>
              </div>
            </div>
            <div className="button" onClick={() => navigate('/qualifiers')} data-html2canvas-ignore="true">
              <FiArrowLeft />
              {widthScreen > 820 && 'Voltar'}
            </div>
          </Header>
          <Content id="playoff-content">
            <div>
              <div className="step-title">Oitavas</div>
              <Oitavas>
                {qualifier.matches
                  .filter(item => item.number <= 4)
                  .map(qual => (
                    <MatchBox
                      onChange={() => (isAtletica ? handleDownloadDoc(qual?.doc, `${qual?.atletica1[0]?.nickname || ""} x ${qual?.atletica1[1]?.nickname || ''}`) : handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Oitavas>
            </div>
            <div>
              <div className="step-title" />
              <div
                style={{
                  maxWidth: 8,
                  height: 1,
                  backgroundColor: 'var(--purple02)',
                  marginTop: '9rem',
                }}
              />
            </div>
            <div>
              <div className="step-title">Quartas</div>
              <Quartas>
                {qualifier.matches
                  .filter(item => item.number === 9 || item.number === 10)
                  .map(qual => (
                    <MatchBox
                    onChange={() => (isAtletica ? handleDownloadDoc(qual?.doc, `${qual?.atletica1[0]?.nickname || ""} x ${qual?.atletica1[1]?.nickname || ''}`) : handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Quartas>
            </div>
            <div>
              <div className="step-title">Semi</div>
              <Semi>
                {qualifier.matches
                  .filter(item => item.number === 13)
                  .map(qual => (
                    <MatchBox
                    onChange={() => (isAtletica ? handleDownloadDoc(qual?.doc, `${qual?.atletica1[0]?.nickname || ""} x ${qual?.atletica1[1]?.nickname || ''}`) : handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Semi>
            </div>
            <div>
              <div className="step-title">Final</div>
              <Final>
                {qualifier.matches
                  .filter(item => item.number === 15)
                  .map(qual => (
                    <MatchBox
                    onChange={() => (isAtletica ? handleDownloadDoc(qual.doc, `${qual?.atletica1[0]?.nickname || ""} x ${qual?.atletica1[1]?.nickname || ''}`) : handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Final>
            </div>
            <div>
              <div className="step-title">Semi</div>
              <Semi>
                {qualifier.matches
                  .filter(item => item.number === 14)
                  .map(qual => (
                    <MatchBox
                    onChange={() => (isAtletica ? handleDownloadDoc(qual.doc, `${qual?.atletica1[0]?.nickname || ""} x ${qual?.atletica1[1]?.nickname || ''}`) : handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Semi>
            </div>
            <div>
              <div className="step-title">Quartas</div>
              <Quartas>
                {qualifier.matches
                  .filter(item => item.number === 11 || item.number === 12)
                  .map(qual => (
                    <MatchBox
                    onChange={() => (isAtletica ? handleDownloadDoc(qual.doc, `${qual?.atletica1[0]?.nickname || ""} x ${qual?.atletica1[1]?.nickname || ''}`) : handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Quartas>
            </div>
            <div>
              <div className="step-title">Oitavas</div>
              <Oitavas>
                {qualifier.matches
                  .filter(item => item.number > 4 && item.number <= 8)
                  .map(qual => (
                    <MatchBox
                    onChange={() => (isAtletica ? handleDownloadDoc(qual.doc, `${qual?.atletica1[0]?.nickname || ""} x ${qual?.atletica1[1]?.nickname || ''}`) : handleEditMatch(qual))}
                      match={qual}
                      key={qual.id}
                    />
                  ))}
              </Oitavas>
            </div>
            {/* {schemaLeft.map(item => {

              const dot1 = document.getElementById(`dot-${item.out1}-out`)?.getBoundingClientRect()
              const dot2 = document.getElementById(`dot-${item.out2}-out`)?.getBoundingClientRect()
              const dot3 = document.getElementById(`dot-${item.in}-in`)?.getBoundingClientRect()

              if(dot1 && dot2 && dot3){
                return (
                  <div key={item.out1} style={{
                    position: 'absolute',
                    top: dot1.top - 1,
                    left: dot1.left,
                    width: dot3.left - dot1.left,
                    height: dot2.top - dot1.top + 1,
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 0,
                  }}>
                    <div
                      style={{
                        height: '100%',
                        width: '50%',
                        borderTop: '1px solid var(--purple02)',
                        borderBottom: '1px solid var(--purple02)',
                        borderRight: '1px solid var(--purple02)'
                      }}
                    />
                    <div
                      style={{
                        height: dot3.top - dot1.top + 1,
                        width: '50%',
                        borderBottom: '1px solid var(--purple02)'
                      }}
                    />
                  </div>
                )
              } else {
                <></>
              }
            })}
            {schemaRight.map(item => {

              const dot1 = document.getElementById(`dot-${item.out1}-out`)?.getBoundingClientRect()
              const dot2 = document.getElementById(`dot-${item.out2}-out`)?.getBoundingClientRect()
              const dot3 = document.getElementById(`dot-${item.in}-in`)?.getBoundingClientRect()

              if(dot1 && dot2 && dot3){
                return (
                  <div key={item.out1} style={{
                    position: 'absolute',
                    top: dot1.top - 1,
                    left: dot3.left,
                    width: dot1.left - dot3.left,
                    height: dot2.top - dot1.top + 1,
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 0,
                  }}>
                  <div
                    style={{
                      height: dot3.top - dot1.top + 1,
                      width: '50%',
                      borderBottom: '1px solid var(--purple02)'
                    }}
                  />
                    <div
                      style={{
                        height: '100%',
                        width: '50%',
                        borderTop: '1px solid var(--purple02)',
                        borderBottom: '1px solid var(--purple02)',
                        borderLeft: '1px solid var(--purple02)'
                      }}
                    />
                  </div>
                )
              } else {
                <></>
              }
              })}
              {(dot13out && dot14out && dot15in && dot15out) && (
                <>
                <div
                  style={{
                    position: 'absolute',
                    width: dot15in.left - dot13out.left,
                    height: '1px',
                    background: 'var(--purple02)',
                    top: dot13out.top - 0.5,
                    left: dot13out.left

                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    width: dot14out.left - dot15out.left,
                    height: '1px',
                    background: 'var(--purple02)',
                    top: dot15out.top - 0.5,
                    left: dot15out.left

                  }}
                />
                </>
              )} */}
          </Content>
        </>
      ) : (
        <div />
      )}
      <ModalMatchBox
        showModalOpen={showModal}
        toggleShowModalOpen={toggleShowModal}
        qualifier={qualifier}
        match={editMatch}
      />
      <ModalSorteio
        showModalOpen={showModalSorteio}
        toggleShowModalOpen={toggleShowModalSorteio}
        qualifier={qualifier}
      />
      <ModalRanking
        showModalOpen={showModalRanking}
        toggleShowModalOpen={toggleShowModalRanking}
        qualifier={qualifier}
      />
      <SelectModalidade
        showModalOpen={showModalSelectModalidade}
        toggleShowModalOpen={toggleShowModalSelectModalidade}
        initialPage="Qualifiers"
      />
    </Container>
  );
}

export default PlayoffPage;
