export type IAdmin = {
  id: string;
  name: string;
  email: string;
  responsibility: string;
  avatar: string;
  city?: string;
  fullName?: string;
  nickname?: string;
  notification?: string[];
};

export type ICaster = {
  id: string;
  name: string;
  email: string;
};

export enum Edition {
  JOIA_2022 = 'joia2022',
  VERAO_2023 = 'verao2023',
  JOIA_2023 = 'joia2023',
  ESPORTS_2023 = 'esports2023',
  VERAO_2024 = 'verao2024',
  JOIA_2024 = 'joia2024',
  IM_2024_A = 'im2024a',
  IM_2024_B = 'im2024b'
}

export interface SelectValue {
  value: string;
  label: string;
}

export type IAtletica = {
  id: string;
  name: string;
  fullName: string;
  nickname: string;
  responsibility: string;
  city: string;
  notification?: string[];
  avatar: string;
  email: string;
  restrictions: {
    Masculino: [string, string][],
    Feminino: [string, string][]
  };
  description: string;
  primaryColor: string;
  secondaryColor: string;
  dob: string;
  mascote: string;
  bestResult: string;
  instagram: string;
};
export type IParty = {
  id: string;
  title: string;
  banner: string;
  link: string;
  description: string;
  date: Date;
  local: string;
}

export type INews = {
  id: string;
  title: string;
  description: string;
  photo: string;
  url: string;
  created_at: Date;
  updated_at: Date;
}

export type IAccommodation = {
  id: string;
  place_id: string;
  place: IPlace[];
  atletica_ids: string[];
  atleticas: IAtletica[];
  description: string;
  room: number;
  available: number;
  bathroom: number;
  security: number;
}

export type IFine = {
  id: string;
  atletica_id: string;
  atletica: IAtletica;
  penalty_id: string;
  penalty: IPenalty[];
  description: string;
  date: Date;
};

export type IMatch = {
  _id?: string;
  id: string;
  number: number;
  atletica_id1: string;
  atletica1: IAtletica[];
  atletica_id2: string;
  atletica2: IAtletica[];
  rep_id1: string;
  rep1: IAtletica[];
  rep_id2: string;
  rep2: IAtletica[];
  score1: number[];
  score2: number[];
  place_id: string;
  place: IPlace[];
  qualifier_id: string;
  qualifier: IQualifier;
  startDate: Date;
  doc?: string;
};

export type IIndividualMatch = {
  _id?: string;
  id: string;
  number: number;
  atletica_id1: string;
  atletica1: IAtletica[];
  atletica_id2: string;
  atletica2: IAtletica[];
  score1: number[];
  score2: number[];
  place_id: string;
  place: IPlace[];
  category_id: string;
  startDate: Date;
  doc?: string;
};

export type IPenalty = {
  id: string;
  name: string;
  law: string;
  point: number;
  fine_id: string[];
  fine: IFine[];
};

export type IPlace = {
  id: string;
  name: string;
  address: string;
  coordinates: [number, number];
  type?: 'field' | 'home' | 'qg';
};

export type IQualifier = {
  id: string;
  type: string;
  name: string;
  atletica_ids: string[];
  atleticas: IAtletica[];
  matches_ids: string[];
  matches: IMatch[];
  point: number[];
  icon: string;
  place_id?: string;
  duration?: {
    Oitavas: number;
    Quartas: number;
    Semi: number;
    Final: number;
  };
  type_id: string;
};

export type ISettings = {
  id: string;
  title: string;
  year: number;
  owner: string;
  ownerAb: string;
  avatar: string;
  classification: {
    atletica_id: string;
    point: number;
  }[];
  standard_points: number[];
  type: {
    label: string;
    value: string;
  }[];
  showClassification: string[];
  rep: boolean;
  atletica_ids: string[];
  atleticas?: IAtletica[];
};

export type IPlayer = {
  id: string;
  name: string;
  course: string;
  cpf: number;
  rg: number;
  doc: string;
  atletica_id: string;
  atletica: IAtletica;
};

export type IIndividuals = {
  id: string;
  type: string;
  name: string;
  categories_id?: string[];
  categories: ICategory[];
  atletica_ids: string[];
  atleticas: IAtletica[];
  points: number[];
  icon: string;
  place_id: string;
  place: IPlace;
  startDate: Date;
  draw?: string[];
  finished?: boolean;
};

export type INotification = {
  id: string;
  atleticas_ids: string[];
  platform: string[];
  message: string;
};

export type ICategory = {
  id: string;
  name: string;
  options: {
    label: string;
    value: string[];
  }[];
  classification: string[];
  classification_aaa?: IAtletica[];
  points: number[];
  individual_id: string;
  hasMatch: boolean;
  matches: IIndividualMatch[];
};
