/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { uploadPhotoAdmin } from '../../../../utils/uploadPhotoAdmin';
import Button from '../../../../components/Button';
import ImagePicker from '../../../../components/ImagePicker';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import { Container, Content, Header } from './styles';
import { IParty } from '../../../../types';
import getValidationError from '../../../../utils/getValidationError';
import FormContainer from '../../../../components/FormContainer';
import TextArea from '../../../../components/TextArea';
import DatePicker from '../../../../components/DatePicker';
import { ptBR } from 'date-fns/locale';
import api from '../../../../services/api';

interface ModalPartyProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  party: IParty;
  refetch: () => void;
}

export function ModalPartyEdit({
  showModalOpen,
  toggleShowModalOpen,
  party,
  refetch
}: ModalPartyProps) {
  const formRef = useRef<FormHandles>(null);
  const [partyTemp, setPartyTemp] = useState(party);

  useEffect(() => {
    setPartyTemp(party);
  }, [party]);

  async function handlePhoto(picture: any): Promise<string> {
    return uploadPhotoAdmin(picture).then(response => {
      if (response) {
        setPartyTemp({
          ...partyTemp,
          banner: response,
        });
        return response;
      }
      return '';
    });
  }
  const handleSubmit = useCallback(
    async (data: IParty) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Campo obrigatório'),
          description: Yup.string().required('Campo obrigatório'),
          link: Yup.string().required('Campo obrigatório'),
          local: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        
        await api.put(`/party/${party.id}`, {...data, banner: partyTemp.banner});
        refetch();
        
        toast.success('Festa editada com sucesso!');
        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao editar Festa');
      }
    },
    [party.id, partyTemp, refetch, toggleShowModalOpen],
  );

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>Atlética</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={partyTemp}
          >
            <FormContainer line="1fr 3fr">
              <div style={{ paddingBottom: '1rem' }}>
                <ImagePicker uploadPhoto={handlePhoto} image={partyTemp.banner} />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'stretch',
                  alignItems: 'start',
                }}
              >
                <Input
                  name="title"
                  placeholder="Título"
                  label="Título:"
                />
                <Input name="link" placeholder="Link para venda de ingresso" label="Link para venda de ingresso:" />
                <FormContainer line="1fr 1fr">
                  <Input name="local" placeholder="Local" label="Local:" />
                  <DatePicker
                    label="Data e horário"
                    defaultDate={new Date()}
                    locale={ptBR}
                    timeFormat="HH:mm"
                    name="date"
                    showTimeSelect
                    timeIntervals={15}
                    dateFormat="HH:mm - dd/MM/yyyy"
                  />
                </FormContainer>
              </div>
            </FormContainer>
            <TextArea
              name='description'
              label='Descrição'
              placeholder='Descrição'
            />
            <div className="buttons">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
                type='button'
              >
                Cancel
              </Button>
              <Button
                type="submit"
                buttonStyle="primary"
                theme="solid"
              >
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}
