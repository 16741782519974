/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useData } from '../../../../hooks/context';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

import { Container, Content, Header } from './styles';
import getValidationError from '../../../../utils/getValidationError';
import ImportData from '../../../../components/ImportData';
import api from '../../../../services/api';
import Select from '../../../../components/Select';

interface SelectProps {
  label: string;
  value: any;
}

interface ModalExportAtletasProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  optionsAtletica: SelectProps[]
}

export function ModalExportAtletas({
  showModalOpen,
  toggleShowModalOpen,
  optionsAtletica
}: ModalExportAtletasProps) {
  const formRef = useRef<FormHandles>(null);
  const [file, setFile] = useState(null);
  const { loading } = useData();
  const [selectedAtletica, setSelectedAtletica] = useState({} as SelectProps);

  async function handlePhoto(file: any): Promise<string> {
    setFile(file)
    return ''
  }

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        
        if(file && selectedAtletica) {
          const data = new FormData();

          data.append('file', file);
          
          api.post(`/player/import/${selectedAtletica.value}`, data)
  
          toast.success('Atletas importados com sucesso!');
          formRef.current?.reset();
          setFile(null);
          toggleShowModalOpen();
        } else {
          
          toast.error('Preencha todos os campos');
        }
        
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao criar Atletica');
      }
    },
    [file, selectedAtletica, toggleShowModalOpen],
  );

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>Importar Atleta</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit} style={{ height: "500px"}}>
            
            <Select
              label="Atlética:"
              name="atletica"
              value={selectedAtletica}
              options={optionsAtletica}
              onChange={e => setSelectedAtletica(e)}
            />
            <ImportData
              uploadFile={handlePhoto}
            />
            <div className="buttons">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                buttonStyle="primary"
                theme="solid"
                loading={loading}
              >
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}
