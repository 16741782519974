import { Form } from '@unform/web';
import React, { useCallback } from 'react';
import { FiX } from 'react-icons/fi';
import { useData } from '../../../../hooks/context';
import { ICaster } from '../../../../types';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import {
    Container,
    Content,
    Header,
} from './styles'

interface ModalCasterProps {
    showModalOpen: boolean;
    toggleShowModalOpen: () => void;
    caster: ICaster;
}

export function ModalCasterEdit({ showModalOpen, toggleShowModalOpen, caster }: ModalCasterProps) {

    const { useCaster, loading } = useData();

    const handleSubmit = useCallback(() => {
        useCaster.updateCaster({
            ...caster,
        });
        toggleShowModalOpen();
    }, [useCaster, caster, toggleShowModalOpen])

    return (
        <Modal
            isOpen={showModalOpen}
            setIsOpen={toggleShowModalOpen}
        >
            <Container>
                <Header>
                    <h2>Caster</h2>
                    <div onClick={toggleShowModalOpen}>
                        <FiX />
                    </div>
                </Header>
                <Content>
                    <Form initialData={caster} onSubmit={handleSubmit}>
                        <Input
                            name="name"
                            placeholder='Nome'
                            label='Nome:'
                            disabled
                        />
                        <Input
                            name="email"
                            placeholder='E-mail'
                            label='E-mail:'
                            disabled
                        />
                        <div className='buttons'>
                            <Button
                                buttonStyle='primary'
                                theme='hollow'
                                onClick={toggleShowModalOpen}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                buttonStyle='primary'
                                theme='solid'
                                loading={loading}
                            >
                                Salvar
                            </Button>
                        </div>
                    </Form>
                </Content>
            </Container>
        </Modal>
    )
}