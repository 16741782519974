/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { uploadPhotoAdmin } from '../../../../utils/uploadPhotoAdmin';
import Button from '../../../../components/Button';
import ImagePicker from '../../../../components/ImagePicker';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import { Container, Content, Header } from './styles';
import { INews } from '../../../../types';
import getValidationError from '../../../../utils/getValidationError';
import FormContainer from '../../../../components/FormContainer';
import TextArea from '../../../../components/TextArea';
import Checkbox from '../../../../components/Checkbox';
import apiWebhook from '../../../../services/apiWebhook';
import { useEdition } from '../../../../hooks/edition';

interface ModalNewsProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  refetch: () => void;
  isAtletica: boolean;
}

export function ModalNews({
  showModalOpen,
  toggleShowModalOpen,
  refetch,
  isAtletica
}: ModalNewsProps) {
  const formRef = useRef<FormHandles>(null);
  const [image, setImage] = useState('');
  const [pushNotification, setPushNotification] = useState(false);
  const { edition } = useEdition();
  

  async function handlePhoto(picture: any): Promise<string> {
    return uploadPhotoAdmin(picture).then(response => {
      if (response) {
        setImage(response);
        return response;
      }
      return '';
    });
  }
  const handleSubmit = useCallback(
    async (data: INews) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Campo obrigatório'),
          description: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await apiWebhook.post(`/news/${isAtletica ? `atletica` : 'admin'}`, {...data, photo: image, pushNotification}, {
          headers: {
            'x-edition': edition
          }
        });
        refetch();

        toast.success('Notícia criada com sucesso!');
        formRef.current?.reset();
        setImage('');
        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao criar Notícia');
      }
    },
    [image, refetch, toggleShowModalOpen, edition, isAtletica, pushNotification],
  );

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>Notícia</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer line="1fr 3fr">
              <div style={{ paddingBottom: '1rem' }}>
                <ImagePicker uploadPhoto={handlePhoto} image={image} />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'stretch',
                  alignItems: 'start',
                }}
              >
                <Input
                  name="title"
                  placeholder="Título"
                  label="Título:"
                />
                <Input name="url" placeholder="Link" label="Link externo:" />
                <Checkbox
                  name='pushNotification'
                  checked={pushNotification}
                  onChange={() => setPushNotification(!pushNotification)}
                >
                  <div>Enviar notificação</div>
                </Checkbox>
              </div>
            </FormContainer>
            <TextArea
              name='description'
              label='Descrição'
              placeholder='Descrição'
            />
            <div className="buttons">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                buttonStyle="primary"
                theme="solid"
              >
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}
