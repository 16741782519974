/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import Modal from '../../../../components/Modal';

import { Container, Header, Content } from './styles';
import getValidationError from '../../../../utils/getValidationError';
import { useData } from '../../../../hooks/context';

import Button from '../../../../components/Button';
import api from '../../../../services/api';
import Input from '../../../../components/Input';

interface ModalTimeMatchProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
}

interface IFormData {

}
interface IDuration {
  [key: string]: {
    Oitavas: number;
    Quartas: number;
    Semi: number;
    Final: number;
  }
}

function ModalTimeMatch({
  showModalOpen,
  toggleShowModalOpen
}: ModalTimeMatchProps) {
  const formRef = useRef<FormHandles>(null);
  const { useQualifier } = useData();

  const {qualifiers} = useQualifier;

  const [selectedType, setSelectedType] = useState('Feminino');
  const [defaultValues, setDefaultValues] = useState<IDuration>({} as IDuration);

  useEffect(() => {
    const defaultValues = qualifiers.reduce((acc: IDuration, quali) => {
      acc[quali.id] = {
          Oitavas: quali.duration?.Oitavas || 0,
          Quartas: quali.duration?.Quartas || 0,
          Semi: quali.duration?.Semi || 0,
          Final: quali.duration?.Final || 0,
      }
      return acc
    }, {})
    setDefaultValues(defaultValues)
  }, [qualifiers])
  
  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        console.log({data})
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.put('/qualifier/durations', {
          duration: data
        })

        formRef.current?.reset();
        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [toggleShowModalOpen],
  );

  const handleType = useCallback((value: string) => {
    setSelectedType(value)
  }, [])



  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>Tempo da partida</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit} initialData={defaultValues} >
            <div className='flex cursor-pointer flex-row w-3/4 items-center justify-center rounded-lg overflow-hidden border border-ea-purple-300 mx-auto'>
              <div
                className={`p-2 w-full text-center ${selectedType === 'Feminino' ? 'bg-ea-purple-300 text-white' : 'text-ea-purple-300'}`}
                onClick={() => handleType('Feminino')}
              >
                Feminino
              </div>
              <div
                className={`p-2 w-full text-center ${selectedType === 'Masculino' ? 'bg-ea-purple-300 text-white' : 'text-ea-purple-300'}`}
                onClick={() => handleType('Masculino')}
              >
                Masculino
              </div>
            </div>
            <div className="flex flex-row items-center justify-start w-full gap-4 mt-8">
              <p className='w-64 font-semibold'>Modalidades</p>
              <p style={{ width: '100px', textAlign: 'center'}}>Oitavas</p>
              <p style={{ width: '100px', textAlign: 'center'}}>Quartas</p>
              <p style={{ width: '100px', textAlign: 'center'}}>Semi</p>
              <p style={{ width: '100px', textAlign: 'center'}}>Final</p>
            </div>
            {qualifiers.map(quali => (
              <div className={`flex flex-row items-center justify-start w-full gap-4 ${selectedType === quali.type ? '' : 'hidden'}`}>
                <p className='w-64'>{quali.name}</p>
                <Input
                  name={`${quali.id}.Oitavas`}
                  placeholder='90 min'
                  type="number"
                  style={{ width: '100px'}}
                />
                <Input
                  name={`${quali.id}.Quartas`}
                  placeholder='90 min'
                  type="number"
                  style={{ width: '100px'}}
                />
                <Input
                  name={`${quali.id}.Semi`}
                  placeholder='90 min'
                  type="number"
                  style={{ width: '100px'}}
                />
                <Input
                  name={`${quali.id}.Final`}
                  placeholder='90 min'
                  type="number"
                  style={{ width: '100px'}}
                />
              </div>
            ))}
            <div className="buttons mt-8">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
              >
                Cancel
              </Button>
              <Button type="submit" buttonStyle="primary" theme="solid">
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}

export default ModalTimeMatch;
