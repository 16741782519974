/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-param-reassign */
import { Form } from '@unform/web';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { FiChevronDown, FiChevronUp, FiClock, FiMap, FiSave } from 'react-icons/fi';
import Select from '../../components/Select';

import {
  Container,
  Header,
  SubHeader,
  Content,
} from './styles';
import Button from '../../components/Button';

import { useData } from '../../hooks/context';
import ModalConfig from './components/ModalConfig';
import { IPlace } from '../../types';
import ModalTimeMatch from './components/ModalTimeMatch';
import Table from '../../components/Table';
import { Column } from 'react-table';
import { Avatar } from '../../components/Avatar';
import { FormHandles } from '@unform/core';
import api from '../../services/api';

function SchedulerConfig() {
  const formRef = useRef<FormHandles>(null)
  const { useAtleticas, useQualifier } = useData();
  const { qualifiers } = useQualifier;
  const { atleticas } = useAtleticas;

  const widthScreen = window.innerWidth;

  const [showFilters, setShowFilters] = useState(true);
  const [selectedPlaces, setSelectedPlaces] = useState<IPlace[]>([])
  const [dataTemp, setDataTemp] = useState<any>()
  const [updateTable, setUpdateTable] = useState(1)

  useEffect(() => {
    let data = [];
    for(const aaa of atleticas) {
      data.push({
        atletica: aaa,
        restrictions: {
          Masculino: aaa?.restrictions?.Masculino?.length > 0 ? aaa?.restrictions?.Masculino : [['', '']],
          Feminino: aaa?.restrictions?.Feminino?.length > 0 ? aaa?.restrictions?.Feminino : [['', '']],
        }
      })
    }
    setDataTemp(data)
  }, [atleticas])

  const [showModal, setShowModal] = useState(false);
  const [showTimeMatchModal, setShowTimeMatchModal] = useState(false);

  const toggleShowTimeMatchModal = useCallback(() => {
    setShowTimeMatchModal(!showTimeMatchModal);
  }, [showTimeMatchModal]);

  const toggleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const toggleShowFilters = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  const qualifiersOptions = useCallback((type: string) => {
    return qualifiers.filter(quali => quali.type === type).map(quali => ({
      label: quali.name,
      value: quali.id
    }))
  }, [qualifiers])

  const handleChange = useCallback((value: any, id: string, type: string, i: number, l: number) => {
    let newData = dataTemp
    const findIndex = newData.findIndex((data: any) => data.atletica.id === id)
    if(findIndex > -1) {
      let restriction = newData[findIndex].restrictions[type]
      if(restriction && restriction?.length > 0 && restriction[i]?.length > 0) {
        restriction = restriction.map((res: any, index: number) => {
          if(index === i) {
            return res.map((r: any, index2: number) => {
              if(index2 === l) {
                return value.value
              }
              return r
            })
          }
          return res
        })
      }
      newData[findIndex].restrictions[type] = restriction
    }
    setDataTemp(newData)
    setUpdateTable(updateTable + 1)
  }, [dataTemp, updateTable])

  const handleSave = useCallback(async () => {
    const atleticas_ids = dataTemp.map((data: any) => data.atletica.id)
    const restrictions = dataTemp.reduce((acc: any, data: any) => {
      acc[data.atletica.id] = data.restrictions
      return acc
    }, {})
    await api.put('/atleticas/restrictions', {
      atleticas_ids,
      restrictions
    }).then(res => console.log(res.data))
  }, [dataTemp])

  function getValue(id: string, type: 'Masculino' | 'Feminino') {
    return qualifiersOptions(type).find(quali => quali.value === id)
  }

  const columns: Column<any>[] = [
            {
              Header: 'Atlética',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px'
                    }}
                  >
                    <Avatar
                      name={row.original.atletica.nickname}
                      avatar={row.original.atletica.avatar}
                    />
                    {row.original.atletica.name}
                  </div>
                );
              },
            },
            {
              Header: 'Restição Masculino',
              Cell: ({ row }) => {
                return (
                  <div className="flex flex-column gap-4 w-full">
                    <Select
                      name={`${row.original.id}.Masculino.0.0`}
                      options={qualifiersOptions('Masculino')}
                      label='1ª Modalidade'
                      value={getValue(row.original.restrictions.Masculino[0][0], 'Masculino')}
                      onChange={(value: any) => handleChange(value, row.original.atletica.id, 'Masculino', 0, 0)}
                    />
                    <Select
                      name={`${row.original.id}.Masculino.0.1`}
                      options={qualifiersOptions('Masculino')}
                      label='2ª Modalidade'
                      value={getValue(row.original.restrictions.Masculino[0][1], 'Masculino')}
                      onChange={(value: any) => handleChange(value, row.original.atletica.id, 'Masculino', 0, 1)}
                    />
                  </div>
                )
              }
            },
            {
              Header: 'Restição Feminino',
              Cell: ({ row }) => {
                return (
                  <div className="flex flex-column gap-4 w-full">
                    <Select
                      name={`${row.original.id}.Feminino.0.0`}
                      options={qualifiersOptions('Feminino')}
                      label='1ª Modalidade'
                      onChange={(value: any) => handleChange(value, row.original.atletica.id, 'Feminino', 0, 0)}
                      value={getValue(row.original.restrictions.Feminino[0][0], 'Feminino')}
                    />
                    <Select
                      name={`${row.original.id}.Feminino.0.1`}
                      options={qualifiersOptions('Feminino')}
                      label='2ª Modalidade'
                      value={getValue(row.original.restrictions.Feminino[0][1], 'Feminino')}
                      onChange={(value: any) => handleChange(value, row.original.atletica.id, 'Feminino', 0, 1)}
                    />
                  </div>
                )
              }
            }
          ]
  
  return (
    <Container>
      <Header>
        <h1>Configuração de Horarios</h1>
        <SubHeader>
          {widthScreen <= 820 && (
            <div>
              <b>Filtros:</b>
              <div onClick={toggleShowFilters}>
                {showFilters ? <FiChevronDown /> : <FiChevronUp />}
              </div>
            </div>
          )}
          {showFilters && (
            <Form onSubmit={() => {}}>
              <Button
                buttonStyle='primary'
                theme='solid'
                style={{
                  marginTop: 0,
                  width: '240px'
                }}
                onClick={toggleShowModal}
              >
                <FiMap
                  style={{ marginRight: '8px'}}
                />
                Praças esportivas
              </Button>
              <Button
                buttonStyle='primary'
                theme='solid'
                style={{
                  marginTop: 0,
                  width: '240px'
                }}
                onClick={toggleShowTimeMatchModal}
              >
                <FiClock
                  style={{ marginRight: '8px'}}
                />
                Tempo da partida
              </Button>
              <Button
                buttonStyle='primary'
                theme='solid'
                style={{
                  marginTop: 0,
                  width: '240px'
                }}
                onClick={handleSave}
              >
                <FiSave
                  style={{ marginRight: '8px'}}
                />
                Salvar restrições
              </Button>
            </Form>
          )}
        </SubHeader>
      </Header>
      <Content>
        <Form ref={formRef} onSubmit={() => {}} style={{ width: '100%' }} >
          {updateTable > 0 && dataTemp?.length > 0 && (
            <Table
              columns={columns}
              data={dataTemp || []}
            />
          )}
        </Form>
      </Content>
      <ModalConfig
        showModalOpen={showModal}
        toggleShowModalOpen={toggleShowModal}
        setSelectedPlaces={setSelectedPlaces}
        selectedPlaces={selectedPlaces}
      />
      <ModalTimeMatch
        showModalOpen={showTimeMatchModal}
        toggleShowModalOpen={toggleShowTimeMatchModal}
      />
    </Container>
  );
}

export default SchedulerConfig;
