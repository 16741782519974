/* eslint-disable react/jsx-curly-newline */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useData } from '../../../../hooks/context';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import { Container, Content, Header } from './styles';
import { IQualifier } from '../../../../types';
import getValidationError from '../../../../utils/getValidationError';
import Select from '../../../../components/Select';
import Chips from '../../../../components/Chips';
import Points from '../../../../components/Points';
import SelectIcons from '../../../../components/SelectIcons';
import FormContainer from '../../../../components/FormContainer';
import produce from 'immer';
import api from '../../../../services/api';

interface ModalQualifierProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
}

interface SelectValue {
  value: string;
  label: string;
}

export function ModalQualifier({
  showModalOpen,
  toggleShowModalOpen,
}: ModalQualifierProps) {
  const formRef = useRef<FormHandles>(null);
  const { useQualifier } = useData();
  const { addQualifier } = useQualifier;

  const [typeTemp, setTypeTemp] = useState<SelectValue>({} as SelectValue);
  const [placeTemp, setPlaceTemp] = useState<SelectValue>({} as SelectValue);
  const [atleticaTemp, setAtleticaTemp] = useState<SelectValue[]>([]);
  const [pointsTemp, setPointsTemp] = useState<number[]>(Array(16).fill(0));
  const [iconsTemp, setIconsTemp] = useState<string>('');
  const [realtimeTypes, setRealtimeTypes] = useState<SelectValue[]>([]);
  const [realtimeType, setRealtimeType] = useState<SelectValue>({} as SelectValue);

  const { useSettings, loading, useAtleticas, usePlace } = useData();
  const { places } = usePlace;
  const { settings } = useSettings;
  const { atleticas } = useAtleticas;

  useEffect(() => {
    if (settings.standard_points) {
      setPointsTemp(settings.standard_points);
    }
  }, [settings]);

  useEffect(() => {
    api.get('realtime/type').then((response: any) => {
      setRealtimeTypes(response.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      })));
    })
  }, [])

  const handleSubmit = useCallback(
    async (data: IQualifier) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          type: Yup.string().required('Tipo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const newQualifier: Omit<
          IQualifier,
          'id' | 'matches' | 'matches_ids' | 'atleticas'
        > = {
          type: typeTemp.value,
          name: data.name,
          atletica_ids: atleticaTemp.map(item => item.value),
          point: pointsTemp,
          icon: iconsTemp,
          place_id: placeTemp.value,
          type_id: realtimeType.value
        };

        addQualifier(newQualifier);

        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao criar Qualifier');
      }
    },
    [
      typeTemp.value,
      atleticaTemp,
      pointsTemp,
      iconsTemp,
      placeTemp.value,
      addQualifier,
      toggleShowModalOpen,
      realtimeType.value,
    ],
  );

  const handleChange = useCallback((value: SelectValue) => {
    setTypeTemp(value);
  }, []);

  const handleChangeRealtimeType = useCallback((value: SelectValue) => {
    setRealtimeType(value);
  }, []);

  const handleChangeIcons = useCallback((value: string) => {
    setIconsTemp(value);
  }, []);

  const handleChangePlace = useCallback((value: SelectValue) => {
    setPlaceTemp(value);
  }, []);

  const handleChangeAtletica = useCallback((value: SelectValue[]) => {
    setAtleticaTemp(value);
  }, []);

  const handleChangePoints = useCallback(
    (value: number, index: number) => {
      const points = produce(pointsTemp, draft => {
        draft[index] = value;
        return draft
      });
      setPointsTemp(points);
    },
    [pointsTemp],
  );

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>Qualifier</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer line="3fr 1fr">
              <Input
                name="name"
                placeholder="Nome da modalidade"
                label="Nome:"
              />
              <Select
                label="Tipo:"
                placeholder="Selecione um tipo"
                name="type"
                options={settings.type}
                value={typeTemp}
                onChange={handleChange}
              />
            </FormContainer>
            <Chips
              name="atleticas"
              label="Atléticas:"
              options={atleticas.map(aaa => ({
                value: aaa.id,
                label: aaa.name,
              }))}
              value={atleticaTemp}
              onChange={handleChangeAtletica}
            />
            <div style={{ justifyContent: 'start', display: 'flex' }}>
              <div
                className="select-all-aaa"
                onClick={() =>
                  handleChangeAtletica(
                    atleticas.map(aaa => ({ value: aaa.id, label: aaa.name })),
                  )
                }
              >
                Selecionar todas as atléticas
              </div>
            </div>
            <Select
              label="Lugar padrão:"
              placeholder="Selecione um lugar (esse campo não é obrigatório)"
              name="place"
              options={places.map(item => ({
                value: item.id,
                label: item.name,
              }))}
              value={placeTemp}
              onChange={handleChangePlace}
            />
            <div>Pontuação:</div>
            <Points onChange={handleChangePoints} value={pointsTemp} />
            <div>Selecione uma imagem:</div>
            <SelectIcons onChange={handleChangeIcons} value={iconsTemp} />
            <div className='flex flex-row items-center justify-stretch whitespace-nowrap mt-4'>
              Tempo real
              <div className='h-px w-full ml-5 bg-gray-600' />
            </div>
            <Select
              label="Tipo da modalidade:"
              placeholder="Selecione um tipo"
              name="type_id"
              options={realtimeTypes}
              value={realtimeType}
              onChange={handleChangeRealtimeType}
            />
            <div className="buttons">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                buttonStyle="primary"
                theme="solid"
                loading={loading}
              >
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}
