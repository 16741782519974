/* eslint-disable react/no-unused-prop-types */
import { useField } from '@unform/core';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import Creatable from 'react-select/creatable';
import { AlertError, Container, ContainerInput } from './styles';

interface InputProps {
  name: string;
  containerStyle?: any;
  icon?: React.ComponentType<IconBaseProps>;
  type?: string;
  label?: string;
  options?: { value: string; label: string }[];
  value?: { value: string; label: string }[];
  placeholder?: string;
  onChange?: (value: any) => void;
  classNameContainer?: string;
}

const Chips: React.FC<InputProps> = ({
  name,
  containerStyle = {},
  label,
  options = [],
  value = [],
  placeholder,
  classNameContainer = "",
  onChange = v => console.log(v),
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [tempValue, setTempValue] = useState(value);
  const selectRef = useRef<any>(null);
  const { fieldName, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (!ref.state.selectValue) {
          return [];
        }
        return ref.state.selectValue.map((option: any) => option.value);
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setTempValue(value);
    setIsFocused(false);
    setIsFilled(false);
  }, [value]);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const customStyles = {
    container: () => ({
      width: '100%',
    }),
    control: () => ({
      width: '100%',
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 999,
    }),
  };

  const handleChange = (newValue: any) => {
    onChange(newValue);
    setTempValue(newValue);
  };

  return (
    <ContainerInput className={classNameContainer}>
      {label && <div className="label">{label}</div>}
      <Container
        style={containerStyle}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        onClick={toggleOpen}
      >
        <Creatable
          ref={selectRef}
          isMulti
          isClearable
          styles={customStyles}
          placeholder={placeholder}
          options={options}
          onChange={handleChange}
          value={tempValue}
        />
        {error && (
          <AlertError title={error}>
            <FiAlertCircle color="#C53038" size={20} />
          </AlertError>
        )}
      </Container>
    </ContainerInput>
  );
};

export default Chips;
