/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { uploadPhotoAdmin } from '../../../../utils/uploadPhotoAdmin';
import Button from '../../../../components/Button';
import ImagePicker from '../../../../components/ImagePicker';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import { Container, Content, Header } from './styles';
import { INews } from '../../../../types';
import getValidationError from '../../../../utils/getValidationError';
import FormContainer from '../../../../components/FormContainer';
import TextArea from '../../../../components/TextArea';
import api from '../../../../services/api';

interface ModalNewsProps {
  showModalOpen: boolean;
  toggleShowModalOpen: () => void;
  news: INews;
  refetch: () => void;
  isAtletica: boolean;
}

export function ModalNewsEdit({
  showModalOpen,
  toggleShowModalOpen,
  news,
  refetch,
  isAtletica,
}: ModalNewsProps) {
  const formRef = useRef<FormHandles>(null);
  const [newsTemp, setNewsTemp] = useState(news);

  useEffect(() => {
    setNewsTemp(news);
  }, [news]);

  async function handlePhoto(picture: any): Promise<string> {
    return uploadPhotoAdmin(picture).then(response => {
      if (response) {
        setNewsTemp({
          ...newsTemp,
          photo: response,
        });
        return response;
      }
      return '';
    });
  }
  const handleSubmit = useCallback(
    async (data: INews) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Campo obrigatório'),
          description: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        
        await api.put(`/news/${isAtletica ? `atletica` : 'admin'}/${news.id}`, {...data, photo: newsTemp.photo});
        refetch();
        
        toast.success('Notícia editada com sucesso!');
        toggleShowModalOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
          return;
        }

        toast.error('Erro ao editar Notícia');
      }
    },
    [news.id, newsTemp, refetch, toggleShowModalOpen, isAtletica],
  );

  return (
    <Modal isOpen={showModalOpen} setIsOpen={toggleShowModalOpen}>
      <Container>
        <Header>
          <h2>Notícia</h2>
          <div onClick={toggleShowModalOpen}>
            <FiX />
          </div>
        </Header>
        <Content>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={newsTemp}
          >
            <FormContainer line="1fr 3fr">
              <div style={{ paddingBottom: '1rem' }}>
                <ImagePicker uploadPhoto={handlePhoto} image={newsTemp.photo} />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'stretch',
                  alignItems: 'start',
                }}
              >
                <Input
                  name="title"
                  placeholder="Título"
                  label="Título:"
                />
                <Input name="url" placeholder="Link" label="Link externo:" />
              </div>
            </FormContainer>
            <TextArea
              name='description'
              label='Descrição'
              placeholder='Descrição'
            />
            <div className="buttons">
              <Button
                buttonStyle="primary"
                theme="hollow"
                onClick={toggleShowModalOpen}
                type='button'
              >
                Cancel
              </Button>
              <Button
                type="submit"
                buttonStyle="primary"
                theme="solid"
              >
                Salvar
              </Button>
            </div>
          </Form>
        </Content>
      </Container>
    </Modal>
  );
}
