import produce from "immer";
import { IIndividuals } from "../types";
import { individualPlayoffClass } from "./individualPlayoffClass";


export function individualClass (individual: IIndividuals) {

    let draw = individual.draw || [];
    
    let allAtleticas = individual.atleticas.map(aaa => ({
        atletica: aaa,
        score: 0,
        positions: Array(32).fill(0),
        categoriesPos: Array(draw.length).fill(Array(32).fill(0))
    }))

    if(individual.categories){
        for(const category of individual.categories) {
            let findIndexDraw = draw.findIndex(item => item === category.id)
            let classif: string[] = category?.hasMatch ? individualPlayoffClass(category, individual).map(item => item.atletica.id) : category.classification
            if(classif){
                //category?.classification?.forEach((classi, index) => {
                for(let i = 0; i < classif.length ; i++){
                    let classi = classif[i];
                    const findIndex = allAtleticas.findIndex(item => item.atletica.id === classi)
                    if(findIndex >= 0) {
                        const newAllAtleticas = produce(allAtleticas, draft => {
                            draft[findIndex].score = draft[findIndex].score + (category.points[i] || 0)
                            draft[findIndex].positions[i] = draft[findIndex].positions[i] + 1
                            if(findIndexDraw >= 0){
                                draft[findIndex].categoriesPos[findIndexDraw][i] = draft[findIndex].categoriesPos[findIndexDraw][i] + 1
                            }
                            return draft
                        })
                        allAtleticas = newAllAtleticas.slice()
                    }
                }
            }
        }
    }

    draw.forEach((item, index) => {
        allAtleticas = allAtleticas.slice().sort((a, b) => {
            const [numberA, numberB] = arrayToNumber(a.categoriesPos[draw.length - 1 - index], b.categoriesPos[draw.length - 1 - index]);
            return numberB - numberA
        })
    })

    const sortedAtleticas = allAtleticas.slice().sort((a, b) => {
        const [numberA, numberB] = arrayToNumber(a.positions, b.positions);
        return numberB - numberA
    }).sort((a, b) => b.score - a.score)

    const formattedRank = sortedAtleticas.map((aaa, index) => (
        {
            ...aaa,
            point: individual.points.length >= (index + 1) ? individual.points[index] : 0
        }
    ))
    
    return formattedRank
}

function arrayToNumber(array1: any[] = [], array2: any[] = []) {
    let a = array1.slice();
    let b = array2.slice();
    let length1 = array1.length
    let length2 = array2.length

    if(length1 > length2) {
        b = [...b, Array(length1 - length2).fill(0)]
    } else if (length2 > length1) {
        a = [...a, Array(length1 - length2).fill(0)]
    }

    return [parseInt(a.join('0')), parseInt(b.join('0'))]

}