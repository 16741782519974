/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-param-reassign */
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp, FiDownload } from 'react-icons/fi';
import Select from '../../components/Select';
import { Calendar,  EventPropGetter,  dateFnsLocalizer } from 'react-big-calendar'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import {
  Container,
  Header,
  SubHeader,
  Content,
} from './styles';
import Button from '../../components/Button';

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { addMinutes, format, getDay, parse, setHours, startOfWeek } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useData } from '../../hooks/context';
import { IMatch, IPlace, IQualifier } from '../../types';
import api from '../../services/api';
import { Avatar } from '../../components/Avatar';
import DatePicker from '../../components/DatePicker';
import ModalMatchBox from '../Playoff/components/ModalMatchBox';
import { getRestriction } from '../../utils/getRestriction';
import Checkbox from '../../components/Checkbox';

const locales = {
  'pt-BR': ptBR,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})
const DnDCalendar = withDragAndDrop(Calendar);

const getTypeColor = (type: string, isSelected?: boolean, isPick?: boolean) => {
  let selected = isSelected ? '500' : isPick ? '600' : '300'
  switch (type) {
    case 'Feminino':
      return `bg-red-${selected}`
    case 'Masculino':
      return `bg-blue-${selected}`
    default:
      return `bg-gray-${selected}`
  }
}

const getTypeLetter = (type: string) => {
  switch (type) {
    case 'Feminino':
      return 'F'
    case 'Masculino':
      return 'M'
    default:
      return ''
  }
}

function Scheduler() {
  const { useQualifier, useAtleticas } = useData();
  const { qualifiers, updateOneMatch } = useQualifier;
  const { atleticas } = useAtleticas;

  const widthScreen = window.innerWidth;

  const [isDownloading, setIsDownloading] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [selectedPlaces, setSelectedPlaces] = useState<IPlace[]>([])
  const [selectedEtapa, setSelectedEtapa] = useState(
    { label: 'Oitavas', value: 'Oitavas' });
  const [selectedType, setSelectedType] = useState(
    { label: 'Todos', value: 'all' });
  const [selectedStep, setSelectedStep] = useState(
    { label: '15 min', value: 15 });
  const [allMatches, setAllMatches] = useState<any[]>([]);
  const [draggedEvent, setDraggedEvent] = useState<any>();
  const [date, setDate] = useState<Date | null>(new Date());
  const [selectedMatch, setSelectedMatch] = useState<IMatch>({} as IMatch);
  const [restrictions, setRestrictions] = useState<string[]>([]);
  const [pickAtletica, setPickAtletica] = useState<string>('');
  const [modalidadesNames, setModalidadesNames] = useState<string[]>([]);
  const [selectedModalidades, setSelectedModalidades] = useState<string[]>([]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let names = qualifiers.map(qualifier => qualifier.name)
    names = names.filter((item, index) => names.indexOf(item) === index)
    console.log(names)
    setModalidadesNames(names)
    setSelectedModalidades(names)
  }, [qualifiers])

  useEffect(() => {
    let matches: IMatch[] = [];
    qualifiers.forEach(qualifier => {
      const fixQualifier = {
        ...qualifier,
        matches: qualifier.matches.map(match => ({
          ...match,
          qualifier: {} as IQualifier
        }))
      }
      let allMatchesQuali = qualifier.matches.map(match => ({
        ...match,
        qualifier: fixQualifier,
      }))
      allMatchesQuali = allMatchesQuali.filter(match => match?.atletica1?.length > 0 && match?.atletica2?.length > 0)
      matches = [...matches, ...allMatchesQuali]
    })
    matches = matches.filter(match => getEtapa(match.number) === selectedEtapa.value);
    matches = matches.filter(match => selectedModalidades.includes(match.qualifier.name))
    if(selectedType.value !== 'all') {
      matches = matches.filter(match => match.qualifier.type === selectedType.value)
    }
    setAllMatches(matches)
  }, [qualifiers, selectedEtapa, selectedType, selectedModalidades])

  useEffect(() => {
    api.get('/place/priority').then(response => {
      setSelectedPlaces(response.data)
    })
  }, [])

  const handleDragStart = useCallback((event: string) => setDraggedEvent(event), [])

  const onDropFromOutside = useCallback(
    ({ start, end, allDay: isAllDay, resource }: any) => {
      if (draggedEvent === 'undroppable') {
        setDraggedEvent(null)
        return
      }

      const match_id = draggedEvent
      setDraggedEvent(null)

      const match = allMatches.find(match => match.id === match_id)
      if (!match) {
        return
      }
      const newMatch = {
        ...match,
        startDate: start,
        place_id: resource,
        qualifier_id: match.qualifier.id
      }
      updateOneMatch(newMatch)
    },
    [draggedEvent, setDraggedEvent, allMatches, updateOneMatch]
  )

  const toggleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  function getEtapa(number: number) {
    if (number <= 8) {
      return 'Oitavas';
    }
    if (number <= 12 && number > 8) {
      return 'Quartas';
    }
    if (number <= 14 && number > 12) {
      return 'Semi';
    }
    return 'Final';
  }

  const toggleShowFilters = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  const handleDownload = useCallback(async (saveAs: 'jpg' | 'pdf') => {
    setIsDownloading(true);
  }, [])

  const onEventResize = (data: any) => {
    console.log(data)
  };

  const onEventDrop = ({event, start, end, resourceId}: any) => {
    const match_id = event.id;

    const match = allMatches.find(match => match.id === match_id)
    if (!match) {
      return
    }
    const newMatch = {
      ...match,
      startDate: start,
      place_id: resourceId,
      qualifier_id: match.qualifier.id
    }
    updateOneMatch(newMatch)
  };

  const handleEventPropGetter: EventPropGetter<any> = useCallback((event, start: Date, end: Date, isSelected: boolean) => {
    let styles = {
    };
    if(event.qualifier.type === 'Feminino') {
      styles = {
        ...styles,
        ...{
          backgroundColor: (isSelected || restrictions.includes(event.id)) ? '#f43f5e' : '#fda4af',
          border: `${(event.atletica_id1 === pickAtletica || event.atletica_id2 === pickAtletica) ? '2px solid #dc2626' : 'none'}`,
          color: 'white',
        }
      }
    } else {
      styles = {
        ...styles,
        ...{
          backgroundColor: (isSelected || restrictions.includes(event.id)) ? '#60a5fa' : '#93c5fd',
          border: `${(event.atletica_id1 === pickAtletica || event.atletica_id2 === pickAtletica) ? '2px solid #2563eb' : 'none'}`,
          color: 'white',
        }
      }
    }
    return {
      style: {
        ...styles,
        borderRadius: '4px',
        padding: '4px'
      },
    }
  }, [restrictions, pickAtletica])

  const handleSelectEvent = useCallback((event: any, e: React.SyntheticEvent<HTMLElement>) => {
    console.log('select', event)
    const restrictions = getRestriction(event, atleticas, qualifiers);
    setRestrictions(restrictions)
  }, [atleticas, qualifiers])

  const handleDoubleClickEvent = useCallback((event: any, e: React.SyntheticEvent<HTMLElement>) => {
    console.log('doubleClick', event)
    const match = allMatches.find(match => match.id === event.id)
    setSelectedMatch(match)
    toggleShowModal()
  }, [allMatches, toggleShowModal])

  const handlePickAtletica = useCallback((atletica_id: string) => {
    if(pickAtletica === atletica_id) {
      setPickAtletica('')
    } else {
      setPickAtletica(atletica_id)
    }
  }, [pickAtletica]);

  const handleChangeChecked = useCallback(
    (value: string) => {
      if (selectedModalidades?.includes(value)) {
        const filteredSelectedModalidades =
        selectedModalidades.filter(item => item !== value);
        setSelectedModalidades(filteredSelectedModalidades);
      } else {
        setSelectedModalidades([...selectedModalidades, value]);
      }
    },
    [selectedModalidades],
  );
  
  return (
    <Container>
      <Header>
        <h1>Criação de Horarios</h1>
        <SubHeader>
          {widthScreen <= 820 && (
            <div>
              <b>Filtros:</b>
              <div onClick={toggleShowFilters}>
                {showFilters ? <FiChevronDown /> : <FiChevronUp />}
              </div>
            </div>
          )}
          {showFilters && (
            <Form onSubmit={() => {}}>
              <div className='flex flex-row items-center justify-between gap-6'>
                <Select
                  label="Etapa:"
                  name="etapa"
                  value={selectedEtapa}
                  options={[
                    { label: 'Oitavas', value: 'Oitavas' },
                    { label: 'Quartas', value: 'Quartas' },
                    { label: 'Semi', value: 'Semi' },
                    { label: 'Final', value: 'Final' },
                  ]}
                  onChange={e => setSelectedEtapa(e)}
                />
                <Select
                  label="Tipo:"
                  name="type"
                  value={selectedType}
                  options={[
                    { label: 'Todos', value: 'all' },
                    { label: 'Masculino', value: 'Masculino' },
                    { label: 'Feminino', value: 'Feminino' },
                  ]}
                  onChange={e => setSelectedType(e)}
                />
                <DatePicker
                  label="Data"
                  defaultDate={new Date()}
                  locale={ptBR}
                  name="startDate"
                  dateFormat="dd/MM/yyyy"
                  onChange={(value) => setDate(value)}
                  value={date}
                />
                <Select
                  label="Step:"
                  name="step"
                  value={selectedStep}
                  options={[
                    { label: '5 min', value: 5 },
                    { label: '10 min', value: 10 },
                    { label: '15 min', value: 15 },
                    { label: '20 min', value: 20 },
                    { label: '30 min', value: 30 },
                  ]}
                  onChange={e => setSelectedStep(e)}
                />
                <Button
                  buttonStyle='primary'
                  theme='solid'
                  style={{
                    marginTop: 0,
                    width: '100px'
                  }}
                  onClick={() => handleDownload('jpg')}
                  loading={isDownloading}
                >
                  <FiDownload
                    style={{ marginRight: '8px'}}
                  />
                  JPG
                </Button>
                <Button
                  buttonStyle='primary'
                  theme='solid'
                  style={{
                    marginTop: 0,
                    width: '100px'
                  }}
                  onClick={() => handleDownload('pdf')}
                  loading={isDownloading}
                >
                  <FiDownload
                    style={{ marginRight: '8px'}}
                  />
                  PDF
                </Button>
              </div>
              <div className="flex flex-row justify-start items-center">
                {modalidadesNames.length > 0 && modalidadesNames?.map(name => (
                  <Checkbox
                    name={name}
                    value={name}
                    checked={selectedModalidades.includes(name)}
                    onChange={() => handleChangeChecked(name)}
                  >
                    {name}
                  </Checkbox>
                ))}
              </div>
            </Form>
          )}
        </SubHeader>
      </Header>
      <Content>
        {allMatches.filter(item => !item?.startDate).length > 0 && (
          <div className="flex w-full border border-ea-purple-900 rounded-lg flex-row items-start justify-start overflow-y gap-4 p-2 flex-wrap">
            {allMatches.filter(item => !item?.startDate).map(match => (
              <Box
                key={match.id}
                handleDragStart={handleDragStart}
                {...match}
              />
            ))}
          </div>
        )}
        <DnDCalendar
          defaultDate={date || new Date()}
          date={date || new Date()}
          scrollToTime={setHours(new Date(), 9)}
          defaultView="day"
          views={['day']}
          events={allMatches?.map(m => ({
            ...m,
            title: <Event handlePickAtletica={handlePickAtletica} pickAtletica={pickAtletica} {...m}/>,
            start: new Date(m.startDate),
            end: addMinutes(new Date(m?.startDate), m?.qualifier?.duration ? m?.qualifier?.duration[getEtapa(m?.number)] : 90 || 90),
            resourceId: m.place_id
          }))}
          eventPropGetter={handleEventPropGetter}
          localizer={localizer}
          onEventDrop={onEventDrop}
          onEventResize={onEventResize}
          style={{ height: "100%", width: '100%' }}
          onDropFromOutside={onDropFromOutside}
          resources={selectedPlaces}
          resourceTitleAccessor={(resource: any) => resource.name}
          resourceIdAccessor={(resource: any) => resource.id}
          onRangeChange={(range: any) => console.log('ramgeChange', range)}
          onDoubleClickEvent={handleDoubleClickEvent}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={(slotInfo: any) => console.log('slotInfo', slotInfo)}
          selectable
          toolbar={false}
          step={selectedStep?.value || 15}
          formats={{
            timeGutterFormat: 'HH:mm',
            eventTimeRangeFormat: (range) => `${format(range.start, 'HH:mm')} - ${format(range.end, 'HH:mm')}`,
          }}
        />
      </Content>
      {selectedMatch?.id && (
        <ModalMatchBox
          match={selectedMatch}
          qualifier={selectedMatch?.qualifier}
          showModalOpen={showModal}
          toggleShowModalOpen={toggleShowModal}
        />
      )}
    </Container>
  );
}

export default Scheduler;

type BoxProps = IMatch & {handleDragStart: (id: string) => void}

const Box = ({qualifier, atletica1, atletica2, id, handleDragStart, ...rest }: BoxProps) => {

  return (
    <div
      draggable={true}
      onDragStart={() => handleDragStart(id)}
      className={`${getTypeColor(qualifier?.type, false)} flex flex-col items-center justify-start w-32 rounded-lg p-2 text-sm font-semibold cursor-grab`}
      style={{
        backgroundColor: qualifier?.type === 'Feminino' ? '#fda4af' : '#93c5fd',
      }}
    >
      <p className='line-clamp-1'>{`${qualifier.name} ${getTypeLetter(qualifier?.type)}`}</p>
      <div className='flex h-full flex-col items-center justify-between text-sm'>
        <div className='flex flex-row items-center justify-center gap-1'>
          {atletica1?.length > 0 && (
            <Avatar
              name={atletica1[0]?.nickname}
              avatar={atletica1[0]?.avatar}
              height='16px'
              width='16px'
            />
          )}
          <p>{atletica1?.length > 0 ? atletica1[0].nickname : 'Aguardando...'}</p>
        </div>
        <p>x</p>
        
        <div className='flex flex-row items-center justify-center gap-1'>
          {atletica2?.length > 0 && (
            <Avatar
              name={atletica2[0]?.nickname}
              avatar={atletica2[0]?.avatar}
              height='16px'
              width='16px'
            />
          )}
          <p>{atletica2?.length > 0 ? atletica2[0].nickname : 'Aguardando...'}</p>
        </div>
      </div>
    </div>
  )
} 

type IEvent = {
  handlePickAtletica: (atletica_id: string) => void;
  pickAtletica: string;
} & IMatch;

const Event = ({qualifier, atletica1, atletica2, handlePickAtletica, pickAtletica}: IEvent) => {
  return (
    <div className="flex flex-col items-center justify-between">
      <p className="line-clamp-1 text-base font-bold">{`${qualifier.name} ${getTypeLetter(qualifier.type)}`}</p>
      <div className='flex h-full flex-col items-center justify-between text-sm w-full relative'>
        <p className='absolute opacity-90' style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>x</p>
        <div className={`flex flex-row items-center justify-center gap-1 w-5/6 p-1 rounded ${pickAtletica === atletica1[0].id ? getTypeColor(qualifier.type, false, true) : ''}`} onClick={() => handlePickAtletica(atletica1[0].id)}>
          <Avatar
            name={atletica1[0].nickname}
            avatar={atletica1[0].avatar}
            height='18px'
            width='18px'
          />
          <p>{atletica1[0].nickname}</p>
        </div>
        <div className={`flex flex-row items-center justify-center gap-1 w-5/6 p-1 rounded ${pickAtletica === atletica2[0].id ? getTypeColor(qualifier.type, false, true) : ''}`} onClick={() => handlePickAtletica(atletica2[0].id)}>
          <Avatar
            name={atletica2[0].nickname}
            avatar={atletica2[0].avatar}
            height='18px'
            width='18px'
          />
          <p>{atletica2[0].nickname}</p>
        </div>
      </div>
    </div>
  )
}