import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from 'react';
import { useRecoilState } from 'recoil';
import {
  Accommodation,
  Admins,
  Atleticas,
  Caster,
  Fines,
  Individuals,
  Notification,
  Penalties,
  Places,
  Players,
  Qualifier,
} from '../Atoms';
import {
  auxAddAdmin,
  auxRemoveAdmin,
  auxUpdateAdmin,
} from '../Atoms/modules/admins';
import {
  auxAddAtletica,
  auxRemoveAtletica,
  auxUpdateAtletica,
} from '../Atoms/modules/atleticas';
import {
  auxAddFine,
  auxRemoveFine,
  auxUpdateFine,
} from '../Atoms/modules/fines';
import {
  auxAddCategory,
  auxAddIndividual,
  auxRemoveIndividual,
  auxUpdateCategory,
  auxUpdateIndividual,
} from '../Atoms/modules/individual';
import { auxAddAllAtleticas, updateAllMatches, updateAMatch } from '../Atoms/modules/match';
import { auxAddNotification } from '../Atoms/modules/notification';
import { auxAddPlayer, auxRemovePlayer, auxUpdatePlayer } from '../Atoms/modules/players';
import {
  auxAddPenalty,
  auxRemovePenalty,
  auxUpdatePenalty,
} from '../Atoms/modules/penalties';
import {
  auxAddPlace,
  auxRemovePlace,
  auxUpdatePlace,
} from '../Atoms/modules/places';
import {
  auxAddQualifier,
  auxRemoveQualifier,
  auxUpdateQualifier,
} from '../Atoms/modules/qualifier';
import api from '../services/api';
import {
  IAccommodation,
  IAdmin,
  IAtletica,
  ICaster,
  ICategory,
  IFine,
  IIndividualMatch,
  IIndividuals,
  IMatch,
  INotification,
  IPenalty,
  IPlace,
  IPlayer,
  IQualifier,
  ISettings,
} from '../types';
import sortAdmins from '../utils/sortAdmin';
import { useAuth } from './auth';
import { useEdition } from './edition';
import { auxAddCaster, auxRemoveCaster, auxUpdateCaster } from '../Atoms/modules/caster';
import { auxAddAccommodation, auxRemoveAccommodation, auxUpdateAccommodation } from '../Atoms/modules/accommodation';
import { updateAllIndividualMatches, auxAddAllAtleticasIndividual } from '../Atoms/modules/individualMatch';

interface DataContextState {
  loading: boolean;
  useAdmins: {
    admins: IAdmin[];
    addAdmin: (admin: Omit<IAdmin, 'id'>) => void;
    removeAdmin: (admin_id: string) => void;
    updateAdmin: (admin: IAdmin) => void;
  };
  useCaster: {
    casters: ICaster[];
    addCaster: (caster: Omit<ICaster, 'id'>) => void;
    removeCaster: (caster_id: string) => void;
    updateCaster: (caster: ICaster) => void;
  };
  useAtleticas: {
    atleticas: IAtletica[];
    addAtletica: (atletica: Omit<IAtletica, 'id'>) => void;
    removeAtletica: (atletica_id: string) => void;
    updateAtletica: (atletica: IAtletica) => void;
  };
  usePenalties: {
    penalties: IPenalty[];
    addPenalty: (penalty: Omit<IPenalty, 'id'>) => void;
    removePenalty: (penalty_id: string) => void;
    updatePenalty: (penalty: IPenalty) => void;
  };
  useFines: {
    fines: IFine[];
    addFine: (fine: Omit<IFine, 'id'>) => void;
    removeFine: (fine_id: string) => void;
    updateFine: (fine: IFine) => void;
  };
  useAccommodations: {
    accommodations: IAccommodation[];
    addAccommodation: (accommodation: Omit<IAccommodation, 'id'>) => void;
    removeAccommodation: (accommodation_id: string) => void;
    updateAccommodation: (accommodation: IAccommodation) => void;
  };
  useQualifier: {
    qualifiers: IQualifier[];
    addQualifier: (
      qualifier: Omit<
        IQualifier,
        'id' | 'matches' | 'matches_ids' | 'atleticas'
      >,
    ) => void;
    removeQualifier: (qualifier_id: string) => void;
    updateQualifier: (qualifier: IQualifier) => void;
    updateMatch: (match: IMatch) => void;
    updateOneMatch: (match: IMatch) => void;
    handleDraftMatch: (allAtleticas: string[], qualifier_id: string) => void;
  };
  useIndividual: {
    individuals: IIndividuals[];
    addIndividual: (
      individual: Omit<IIndividuals, 'id' | 'atleticas' | 'place'>,
    ) => void;
    removeIndividual: (individual_id: string) => void;
    updateIndividual: (individual: IIndividuals) => void;
    addCategory: (category: Omit<ICategory, 'id' | 'classification' | 'matches'>) => void;
    updateCategory: (category: ICategory) => void;
    updateIndividualMatch: (match: IIndividualMatch, individual_id: string) => void;
    handleDraftIndividualMatch: (allAtleticas: string[], category_id: string, individuals_id: string) => void;
  };
  usePlace: {
    places: IPlace[];
    addPlace: (place: Omit<IPlace, 'id'>) => void;
    removePlace: (place_id: string) => void;
    updatePlace: (place: IPlace) => void;
  };
  usePlayers: {
    players: IPlayer[];
    addPlayer: (player: Omit<IPlayer, 'id'>) => void;
    removePlayer: (player_id: string) => void;
    updatePlayer: (player: IPlayer) => void;
  };
  useSettings: {
    settings: ISettings;
    updateSettings: (settings: Omit<ISettings, 'id'>) => void;
  };
  useNotification: {
    notifications: INotification[];
    addNotification: (notification: Omit<INotification, 'id'>) => void;
  };
}

export const DataContext = createContext<DataContextState>(
  {} as DataContextState,
);

interface DataProviderProps {
  children: ReactNode;
}

export function DataProvider({ children }: DataProviderProps) {
  const { admin } = useAuth();
  const [loading, setLoading] = useState(true);
  const [admins, setAdmins] = useRecoilState(Admins);
  const [casters, setCasters] = useRecoilState(Caster);
  const [atleticas, setAtleticas] = useRecoilState(Atleticas);
  const [penalties, setPenalties] = useRecoilState(Penalties);
  const [fines, setFines] = useRecoilState(Fines);
  const [accommodations, setAccommodations] = useRecoilState(Accommodation);
  const [qualifiers, setQualifier] = useRecoilState(Qualifier);
  const [players, setPlayers] = useRecoilState(Players);
  const [places, setPlaces] = useRecoilState(Places);
  const [individuals, setIndividuals] = useRecoilState(Individuals);
  const [notifications, setNotification] = useRecoilState(Notification);
  const [settings, setSettings] = useState<ISettings>({} as ISettings);
  const { edition } = useEdition();
  const fetchAllData = useCallback(async () => {
    setLoading(true);
    try {
      api.defaults.headers.common['x-edition'] = edition;
      const responseAdmin = await api.get<IAdmin[]>('/admins');
      setAdmins(sortAdmins(responseAdmin.data));
      const responseSettings = await api.get<ISettings>('/settings');
      setSettings(responseSettings.data);
      const responseAtleticas = await api.get<IAtletica[]>('/atleticas');
      setAtleticas(responseAtleticas.data.slice().sort((a, b) => a.name.localeCompare(b.name)));
      const responseCaster = await api.get<ICaster[]>('/caster');
      const responsePlayers = await api.get<IPlayer[]>('/player');
      setPlayers(responsePlayers.data);
      setCasters(responseCaster.data.slice().sort((a, b) => a.name.localeCompare(b.name)));
      const responsePenalties = await api.get<IPenalty[]>('/penalty');
      setPenalties(responsePenalties.data.slice().filter(item => !!item?.name).sort((a, b) => a.name.localeCompare(b.name)));
      const responseFine = await api.get<IFine[]>('/fine');
      setFines(responseFine.data);
      const responseQualifiers = await api.get<IQualifier[]>('/qualifier');
      setQualifier(responseQualifiers.data.slice().filter(item => !!item?.name).sort((a, b) => a.name.localeCompare(b.name)));
      const responsePlaces = await api.get<IPlace[]>('/place');
      setPlaces(responsePlaces.data);
      const responseAccommodation = await api.get<IAccommodation[]>('/accommodation');
      setAccommodations(responseAccommodation.data);
      const responseIndividuals = await api.get<IIndividuals[]>('/individual');
      setIndividuals(responseIndividuals.data.slice().filter(item => !!item?.name).sort((a, b) => a.name.localeCompare(b.name)));
      setLoading(false);
      return true;
    } catch (e) {
      setLoading(false);
      console.log(e);
      return false;
    }
  }, [
    edition,
    setAdmins,
    setAtleticas,
    setPlayers,
    setCasters,
    setPenalties,
    setFines,
    setQualifier,
    setPlaces,
    setIndividuals,
    setAccommodations
  ]);

  useEffect(() => {
    setLoading(true);
    if (admin) {
      fetchAllData()
    }
  }, [admin, fetchAllData, edition]);

  async function addAdmin(admin: Omit<IAdmin, 'id'>) {
    setLoading(true);
    await auxAddAdmin(admin, setAdmins, admins);
    setLoading(false);
  }

  async function updateAdmin(admin: IAdmin) {
    setLoading(true);
    await auxUpdateAdmin(admin, setAdmins, admins);
    setLoading(false);
  }

  async function removeAdmin(admin_id: string) {
    await auxRemoveAdmin(admin_id, setAdmins, admins);
  }

  

  async function addCaster(caster: Omit<ICaster, 'id'>) {
    setLoading(true);
    await auxAddCaster(caster, setCasters, casters);
    setLoading(false);
  }

  async function updateCaster(caster: ICaster) {
    setLoading(true);
    await auxUpdateCaster(caster, setCasters, casters);
    setLoading(false);
  }

  async function removeCaster(Caster_id: string) {
    await auxRemoveCaster(Caster_id, setCasters, casters);
  }

  async function addAtletica(atletica: Omit<IAtletica, 'id'>) {
    setLoading(true);
    await auxAddAtletica(atletica, setAtleticas, atleticas);
    setLoading(false);
  }

  async function updateAtletica(atletica: IAtletica) {
    setLoading(true);
    await auxUpdateAtletica(atletica, setAtleticas, atleticas);
    setLoading(false);
  }

  async function removeAtletica(atletica_id: string) {
    await auxRemoveAtletica(atletica_id, setAtleticas, atleticas);
  }

  async function addPenalty(penalty: Omit<IPenalty, 'id'>) {
    setLoading(true);
    await auxAddPenalty(penalty, setPenalties, penalties);
    setLoading(false);
  }

  async function updatePenalty(penalty: IPenalty) {
    setLoading(true);
    await auxUpdatePenalty(penalty, setPenalties, penalties);
    setLoading(false);
  }

  async function removePenalty(penalty_id: string) {
    await auxRemovePenalty(penalty_id, setPenalties, penalties);
  }

  async function addFine(fine: Omit<IFine, 'id'>) {
    setLoading(true);
    await auxAddFine(fine, setFines, fines, atleticas, penalties);
    setLoading(false);
  }

  async function updateFine(Fines: IFine) {
    setLoading(true);
    await auxUpdateFine(Fines, setFines, fines, atleticas, penalties);
    setLoading(false);
  }

  async function removeFine(Fines_id: string) {
    await auxRemoveFine(Fines_id, setFines, fines);
  }

  async function addAccommodation(accommodation: Omit<IAccommodation, 'id'>) {
    setLoading(true);
    await auxAddAccommodation(accommodation, setAccommodations, accommodations, atleticas, places);
    setLoading(false);
  }

  async function updateAccommodation(Accommodations: IAccommodation) {
    setLoading(true);
    await auxUpdateAccommodation(Accommodations, setAccommodations, accommodations, atleticas, places);
    setLoading(false);
  }

  async function removeAccommodation(Accommodations_id: string) {
    await auxRemoveAccommodation(Accommodations_id, setAccommodations, accommodations);
  }

  async function addQualifier(
    qualifier: Omit<IQualifier, 'id' | 'matches' | 'matches_ids' | 'atleticas'>,
  ) {
    setLoading(true);
    await auxAddQualifier(
      qualifier,
      setQualifier,
      qualifiers,
      atleticas,
      places,
    ).then(() => setLoading(false));
  }

  async function updateQualifier(qualifier: IQualifier) {
    setLoading(true);
    await auxUpdateQualifier(
      qualifier,
      setQualifier,
      qualifiers,
      atleticas,
    ).then(() => setLoading(false));
  }

  async function handleDraftMatch(
    allAtleticas: string[],
    qualifier_id: string,
  ) {
    setLoading(true);
    await auxAddAllAtleticas(
      allAtleticas,
      qualifier_id,
      setQualifier,
      qualifiers,
      atleticas,
      places,
    ).then(() => setLoading(false));
  }

  async function handleDraftIndividualMatch(
    allAtleticas: string[],
    category_id: string,
    individual_id: string
  ) {
    setLoading(true);
    await auxAddAllAtleticasIndividual(
      allAtleticas,
      category_id,
      individual_id,
      setIndividuals,
      individuals,
      atleticas,
      places,
    ).then(() => setLoading(false));
  }

  async function updateMatch(match: IMatch) {
    setLoading(true);
    await updateAllMatches(
      match,
      setQualifier,
      qualifiers,
      atleticas,
      places,
    ).then(() => setLoading(false));
  }
  async function updateIndividualMatch(match: IIndividualMatch, individual_id: string) {
    setLoading(true);
    await updateAllIndividualMatches(
      match,
      setIndividuals,
      individual_id,
      individuals,
      atleticas,
      places,
    ).then(() => setLoading(false));
  }

  async function updateOneMatch(match: IMatch) {
    setLoading(true);
    await updateAMatch(
      match,
      setQualifier,
      qualifiers,
      atleticas,
      places,
    ).then(() => setLoading(false));
  }

  async function removeQualifier(qualifier_id: string) {
    await auxRemoveQualifier(qualifier_id, setQualifier, qualifiers);
  }

  async function addIndividual(
    individual: Omit<IIndividuals, 'id' | 'atleticas' | 'place'>,
  ) {
    setLoading(true);
    await auxAddIndividual(
      individual,
      setIndividuals,
      individuals,
      atleticas,
      places,
    ).then(() => setLoading(false));
  }

  async function updateIndividual(individual: IIndividuals) {
    setLoading(true);
    await auxUpdateIndividual(
      individual,
      setIndividuals,
      individuals,
      atleticas,
    ).then(() => setLoading(false));
  }

  async function removeIndividual(individual_id: string) {
    await auxRemoveIndividual(individual_id, setIndividuals, individuals);
  }

  async function addCategory(
    category: Omit<ICategory, 'id' | 'classification' | 'matches'>,
  ) {
    setLoading(true);
    await auxAddCategory(category, setIndividuals, individuals);
    setLoading(false);
  }

  async function updateCategory(category: ICategory) {
    setLoading(true);
    await auxUpdateCategory(category, setIndividuals, individuals);
    setLoading(false);
  }

  async function addNotification(notification: Omit<INotification, 'id'>) {
    setLoading(true);
    await auxAddNotification(notification, setNotification, notifications).then(() => setLoading(false));
  }

  async function addPlace(place: Omit<IPlace, 'id'>) {
    setLoading(true);
    await auxAddPlace(place, setPlaces, places).then(() => setLoading(false));
  }

  async function updatePlace(Place: IPlace) {
    setLoading(true);
    await auxUpdatePlace(Place, setPlaces, places);
    setLoading(false);
  }

  async function removePlace(place_id: string) {
    await auxRemovePlace(place_id, setPlaces, places);
  }

  const updateSettings = useCallback(
    async (settings: Omit<ISettings, 'id'>) => {
      try {
        api.put('/settings', settings).then(response => {
          setSettings(response.data);
        });
      } catch (e) {
        console.log(e);
      }
    },
    [],
  );
  
  async function addPlayer(player: Omit<IPlayer, 'id'>) {
    setLoading(true);
    await auxAddPlayer(player, setPlayers, players);
    setLoading(false);
  }

  async function updatePlayer(player: IPlayer) {
    setLoading(true);
    await auxUpdatePlayer(player, setPlayers, players);
    setLoading(false);
  }

  async function removePlayer(player_id: string) {
    await auxRemovePlayer(player_id, setPlayers, players);
  }
  const usePlayers = {
    players,
    addPlayer,
    removePlayer,
    updatePlayer,
  };
  const useAdmins = { admins, addAdmin, removeAdmin, updateAdmin };
  const useCaster = { casters, addCaster, removeCaster, updateCaster };
  const useAtleticas = {
    atleticas,
    addAtletica,
    removeAtletica,
    updateAtletica,
  };
  const usePenalties = { penalties, addPenalty, removePenalty, updatePenalty };
  const useFines = { fines, addFine, removeFine, updateFine };
  const useAccommodations = { accommodations, addAccommodation, removeAccommodation, updateAccommodation };
  const useQualifier = {
    qualifiers,
    addQualifier,
    removeQualifier,
    updateQualifier,
    updateMatch,
    handleDraftMatch,
    updateOneMatch
  };
  const useIndividual = {
    individuals,
    addIndividual,
    removeIndividual,
    updateIndividual,
    addCategory,
    updateCategory,
    updateIndividualMatch,
    handleDraftIndividualMatch
  };
  const usePlace = { places, addPlace, removePlace, updatePlace };
  const useNotification = { notifications, addNotification };
  const useSettings = { settings, updateSettings };

  return (
    <DataContext.Provider
      value={{
        loading,
        useAdmins,
        useCaster,
        useSettings,
        useAtleticas,
        usePenalties,
        useFines,
        useQualifier,
        usePlace,
        useIndividual,
        useNotification,
        useAccommodations,
        usePlayers
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export function useData(): DataContextState {
  const context = useContext(DataContext);

  if (!context) {
    throw new Error('useAuth must be used within an DataProvider');
  }
  return context;
}
