import { SetterOrUpdater } from "recoil";
import api from "../../services/api";
import { IPlayer } from "../../types";


export async function auxUpdatePlayer(player: IPlayer, setPlayers: SetterOrUpdater<IPlayer[]>, state: IPlayer[]) {
  try {
    api.put(`/player/${player.id}`, player).then(response => {
      const index = state.findIndex(item => item.id === player.id);
      const updatedPlayer = state.slice();
      updatedPlayer[index] = response.data;
      setPlayers(updatedPlayer);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxRemovePlayer(player_id: string, setPlayers: SetterOrUpdater<IPlayer[]>, state: IPlayer[]) {
  try {
    api.delete(`/player/${player_id}`).then(response => {
      const filteredPlayers = state.filter(atletica => atletica.id !== player_id)
      setPlayers(filteredPlayers);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxAddPlayer(player: Omit<IPlayer, 'id'>, setPlayers: SetterOrUpdater<IPlayer[]>, state: IPlayer[]) {
  try {
    api.post('/player', player).then(response => {
      setPlayers([...state, response.data]);
    })
  } catch (e) {
    console.log(e)
  }
}