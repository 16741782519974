import { useCallback, useEffect, useMemo, useState } from 'react';
import { FiEdit, FiPlus, FiTrash2, FiUpload } from 'react-icons/fi';
import { Column } from 'react-table';
import { ModalAtletica } from './components/ModalAtletica';
import { ModalAtleticaEdit } from './components/ModalAtleticaEdit';
import { ModalWaring } from '../../components/ModalWaring';
import Table from '../../components/Table';
import { useAuth } from '../../hooks/auth';
import { useData } from '../../hooks/context';
import { IAtletica, IPlayer } from '../../types';

import { Container, IconContainer, Header, AddNew } from './styles';
import { Form } from '@unform/web';
import Select from '../../components/Select';
import FormContainer from '../../components/FormContainer';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import { ModalExportAtletas } from './components/ModalExportAtleta';

interface SelectProps {
  label: string;
  value: any;
}

function PageAtletas() {
  const widthScreen = window.innerWidth;
  const { admin } = useAuth();
  const isAtletica = useMemo(
    () => admin?.responsibility === 'atletica',
    [admin],
  );
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [showExportPlayerModal, setShowExportPlayerModal] = useState(false);
  const [showPlayerModalEdit, setShowPlayerModalEdit] = useState(false);
  const [showPlayerModalDelete, setShowPlayerModalDelete] = useState(false);
  const [editPlayer, setEditPlayer] = useState({} as IAtletica);
  const [deletePlayer, setDeletePlayer] = useState('');
  const { usePlayers, useAtleticas } = useData();
  const [selectedAtletica, setSelectedAtletica] = useState({
    label: 'Todas',
    value: 'all',
  });
  const [selectedTypeSearch, setSelectedTypeSearch] = useState();
  const [optionsAtletica, setOptionsAtletica] = useState<SelectProps[]>([]);
  const [players, setPlayers] = useState<IPlayer[]>([]);

  useEffect(() => {
    const createOptions = useAtleticas.atleticas?.map(aaa => ({
      label: aaa.nickname,
      value: aaa.id,
    }));
    setOptionsAtletica([{ label: 'Todas', value: 'all' }, ...createOptions]);
  }, [useAtleticas.atleticas]);

  useEffect(() => {
    api.get('/player').then(response => {
      setPlayers(response.data);
    });
  }, [])

  const toggleShowModalOpenEdit = useCallback(() => {
    setShowPlayerModalEdit(!showPlayerModalEdit);
  }, [showPlayerModalEdit]);

  const toggleShowModalOpenExport = useCallback(() => {
    setShowExportPlayerModal(!showExportPlayerModal);
  }, [showExportPlayerModal]);

  const toggleShowModalOpenDelete = useCallback(() => {
    setShowPlayerModalDelete(!showPlayerModalDelete);
  }, [showPlayerModalDelete]);

  const handleEditPlayer = useCallback(
    (atleticaEdit: IAtletica) => {
      toggleShowModalOpenEdit();
      setEditPlayer(atleticaEdit);
    },
    [toggleShowModalOpenEdit],
  );

  const handleDeletePlayer = useCallback(
    (atleticasId: string) => {
      toggleShowModalOpenDelete();
      setDeletePlayer(atleticasId);
    },
    [toggleShowModalOpenDelete],
  );

  const delPlayer = useCallback(() => {
    usePlayers.removePlayer(deletePlayer);
    toggleShowModalOpenDelete();
  }, [usePlayers, deletePlayer, toggleShowModalOpenDelete]);

  const columns: Column<any>[] = useMemo(
    () =>
      isAtletica
        ? [
            {
              Header: 'Nome',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.name}
                  </div>
                );
              },
            },
            {
              Header: 'CPF',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.cpf}
                  </div>
                );
              },
            },
            {
              Header: 'RG',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.rg}
                  </div>
                );
              },
            },
            {
              Header: 'DOC',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.doc}
                  </div>
                );
              },
            },
            {
              Header: 'Curso',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.course}
                  </div>
                );
              },
            },
          ]
        : [
          {
            Header: 'Nome',
            Cell: ({ row }) => {
              return (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {row.original.name}
                </div>
              );
            },
          },
          {
            Header: 'Atlética',
            Cell: ({ row }) => {
              return (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {row.original.atletica[0].name}
                </div>
              );
            },
          },
          {
            Header: 'CPF',
            Cell: ({ row }) => {
              return (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {row.original.cpf}
                </div>
              );
            },
          },
          {
            Header: 'RG',
            Cell: ({ row }) => {
              return (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {row.original.rg}
                </div>
              );
            },
          },
          {
            Header: 'DOC',
            Cell: ({ row }) => {
              return (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {row.original.doc}
                </div>
              );
            },
          },
          {
            Header: 'Curso',
            Cell: ({ row }) => {
              return (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {row.original.course}
                </div>
              );
            },
          },
            {
              Header: 'Opções',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IconContainer
                      type="edit"
                      onClick={() => handleEditPlayer(row.original)}
                    >
                      <FiEdit />
                      {widthScreen <= 820 && 'Editar'}
                    </IconContainer>
                    <IconContainer
                      type="del"
                      onClick={() => handleDeletePlayer(row.original.id)}
                    >
                      <FiTrash2 />
                      {widthScreen <= 820 && 'Deletar'}
                    </IconContainer>
                  </div>
                );
              },
            },
          ],
    [isAtletica, handleEditPlayer, handleDeletePlayer, widthScreen],
  );

  const toggleShowModalOpen = useCallback(() => {
    setShowPlayerModal(!showPlayerModal);
  }, [showPlayerModal]);

  return (
    <Container>
      <Header>
        <h1>Atletas</h1>
        {!isAtletica && (
          <div>
            <AddNew onClick={toggleShowModalOpen}>
              <FiPlus />
              New
            </AddNew>
            <AddNew onClick={toggleShowModalOpenExport}>
              <FiUpload />
              Importar
            </AddNew>
          </div>
        )}
      </Header>
      <div>
        <Form onSubmit={() => {}} style={{padding: "16px 24px 0"}}>
          <FormContainer line='1fr 1fr 3fr 1fr'>
            <Select
              label="Atlética:"
              name="atletica"
              value={selectedAtletica}
              options={optionsAtletica}
              onChange={e => setSelectedAtletica(e)}
            />
            <Select
              label="Filtro:"
              name="searchType"
              value={selectedTypeSearch}
              placeholder='Selecionar um filtro'
              options={[
                { value: 'name', label: 'Nome' },
                { value: 'cpf', label: 'CPF' },
                { value: 'rg', label: 'RG' },
                { value: 'doc', label: 'DOC' },
                { value: 'course', label: 'Curso' },
              ]}
              onChange={e => setSelectedTypeSearch(e)}
            />
            <Input
              label='Pesquisar:'
              name='search'
              placeholder={!selectedTypeSearch ? 'Selecionar um filtro' : 'Pesquisar'}
              disabled={!selectedTypeSearch}	
            />
            <Button
              buttonStyle='primary'
              style={{ height: '40px', marginTop: '22px' }}
            >
              Pesquisar
            </Button>
          </FormContainer>
        </Form>
        <Table
          columns={columns}
          data={isAtletica ? players.filter(p => p.atletica_id === admin.id) : players}
        />
      </div>
      

      <ModalExportAtletas
        showModalOpen={showExportPlayerModal}
        toggleShowModalOpen={toggleShowModalOpenExport}
        optionsAtletica={optionsAtletica}
      />
      <ModalAtletica
        showModalOpen={showPlayerModal}
        toggleShowModalOpen={toggleShowModalOpen}
      />
      <ModalAtleticaEdit
        showModalOpen={showPlayerModalEdit}
        toggleShowModalOpen={toggleShowModalOpenEdit}
        atletica={editPlayer}
      />
      <ModalWaring
        showModalOpen={showPlayerModalDelete}
        toggleShowModalOpen={toggleShowModalOpenDelete}
        title="Deletar atletica"
        message="Tem certeza que deseja deletar esse atletica?"
        textLeftButton="Cancelar"
        textRightButton="Deletar"
        handleLeftButton={toggleShowModalOpenDelete}
        handleRightButton={delPlayer}
      />
    </Container>
  );
}

export default PageAtletas;
