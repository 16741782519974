import { IconBaseProps } from 'react-icons';

import {
  FiAlertTriangle,
  FiAward,
  FiCalendar,
  FiMapPin,
  FiPhone,
  FiSettings,
  FiShield,
  FiUserPlus,
  FiUsers,
} from 'react-icons/fi';
import { MdDirectionsRun, MdSportsHandball, MdNotifications } from 'react-icons/md';
import { GiBeerStein } from 'react-icons/gi'
import { GoLaw } from 'react-icons/go';
import { BsTable } from 'react-icons/bs';
import { FaCampground } from "react-icons/fa";
import Atleticas from '../pages/Atleticas';

import Dashboard from '../pages/Dashboard';
import Org from '../pages/Org';
import Penalties from '../pages/Penalties';
import Estatuto from '../pages/Estatuto';
import Places from '../pages/Places';
import Qualifier from '../pages/Qualifier';
import Individuals from '../pages/Individuais';

import Schedule from '../pages/Schedule';
import Classificacao from '../pages/Classificacao';
import Calendar from '../pages/Scheduler';
import SchedulerConfig from '../pages/SchedulerConfig';
import PageAccommodation from '../pages/Accommodation';
import Caster from '../pages/Caster';
import PageParty from '../pages/Party';
import PageNews from '../pages/News';
import PageAtletas from '../pages/Atletas';

interface MenuOptionsProps {
  title: string;
  path: string;
  component: () => JSX.Element;
  icon: React.ComponentType<IconBaseProps>;
}
export interface MenuProps {
  title: string;
  path: string;
  icon: React.ComponentType<IconBaseProps>;
  component: () => JSX.Element;
  subMenu: MenuOptionsProps[];
}



const globalMenu = {
  admin: [
    {
      title: 'Jogos',
      path: '/',
      icon: FiAward,
      component: Classificacao,
      subMenu: [
        {
          title: 'Classificação',
          path: '/',
          icon: FiAward,
          component: Classificacao,
          subMenu: [],
        },
        {
          title: 'Tabelas',
          path: '/dashboard',
          icon: BsTable,
          component: Dashboard,
          subMenu: [],
        },
        {
          title: 'C.O.',
          path: '/co',
          icon: FiUsers,
          component: Org,
          subMenu: [],
        },
        {
          title: 'Atleticas',
          path: '/atleticas',
          icon: FiShield,
          component: Atleticas,
          subMenu: [],
        },
      ],
    },
    {
      title: 'Logística',
      path: '/',
      icon: FiAward,
      component: Classificacao,
      subMenu: [
        {
          title: 'Programação',
          path: '/schedule',
          icon: FiCalendar,
          component: Schedule,
          subMenu: [],
        },
        {
          title: 'Horarios',
          path: '/calendar',
          icon: FiCalendar,
          component: Calendar,
          subMenu: [],
        },
        {
          title: 'Configurações',
          path: '/schedule-config',
          icon: FiSettings,
          component: SchedulerConfig,
          subMenu: [],
        },
        {
          title: 'Praças esportivas',
          path: '/places',
          icon: FiMapPin,
          component: Places,
          subMenu: [],
        },
      ]
    },
    {
      title: 'Modalidades',
      path: '/qualifiers',
      icon: MdSportsHandball,
      component: Qualifier,
      subMenu: [
        {
          title: 'Com chaveamento',
          path: '/qualifiers',
          component: Qualifier,
          icon: MdSportsHandball,
        },
        {
          title: 'Sem chaveamento',
          path: '/individuais',
          component: Individuals,
          icon: MdDirectionsRun,
        },
      ],
    },
    {
      title: 'Alojamento',
      path: '/aloja',
      icon: MdSportsHandball,
      component: PageAccommodation,
      subMenu: [
        {
          title: 'Geral',
          path: '/aloja',
          component: PageAccommodation,
          icon: FaCampground,
        },
        {
          title: 'Mapa',
          path: '/places?filter=home',
          icon: FiMapPin,
          component: Places,
          subMenu: [],
        },
      ],
    },
    {
      title: 'Penalidades & Multas',
      path: '/penalidades',
      icon: FiAlertTriangle,
      component: Penalties,
      subMenu: [
        {
          title: 'Penalidades',
          path: '/penalidades',
          icon: FiAlertTriangle,
          component: Penalties,
          subMenu: [],
        },
        {
          title: 'Estatuto',
          path: '/estatuto',
          icon: GoLaw,
          component: Estatuto,
          subMenu: [],
        },
      ],
    }
  ] as MenuProps[],
  atletica: [
    {
      title: 'Jogos',
      path: '/',
      icon: FiAward,
      component: Classificacao,
      subMenu: [
        {
          title: 'Classificação',
          path: '/',
          icon: FiAward,
          component: Classificacao,
          subMenu: [],
        },
        {
          title: 'C.O.',
          path: '/co',
          icon: FiUsers,
          component: Org,
          subMenu: [],
        },
        {
          title: 'Atleticas',
          path: '/atleticas',
          icon: FiShield,
          component: Atleticas,
          subMenu: [],
        },
      ],
    },
    {
      title: 'Logística',
      path: '/',
      icon: FiAward,
      component: Classificacao,
      subMenu: [
        {
          title: 'Programação',
          path: '/schedule',
          icon: FiCalendar,
          component: Schedule,
          subMenu: [],
        },
        {
          title: 'Praças esportivas',
          path: '/places',
          icon: FiMapPin,
          component: Places,
          subMenu: [],
        },
      ]
    },
    {
      title: 'Modalidades',
      path: '/qualifiers',
      icon: MdSportsHandball,
      component: Qualifier,
      subMenu: [
        {
          title: 'Com chaveamento',
          path: '/qualifiers',
          component: Qualifier,
          icon: MdSportsHandball,
        },
        {
          title: 'Sem chaveamento',
          path: '/individuais',
          component: Individuals,
          icon: MdDirectionsRun,
        },
      ],
    },
    {
      title: 'Alojamento',
      path: '/aloja',
      icon: MdSportsHandball,
      component: Qualifier,
      subMenu: [
        {
          title: 'Geral',
          path: '/aloja',
          component: PageAccommodation,
          icon: FaCampground,
        },
        {
          title: 'Mapa',
          path: '/places?filter=home',
          icon: FiMapPin,
          component: Places,
          subMenu: [],
        },
      ],
    },
    {
      title: 'Penalidades & Multas',
      path: '/penalidades',
      icon: FiAlertTriangle,
      component: Penalties,
      subMenu: [
        {
          title: 'Penalidades',
          path: '/penalidades',
          icon: FiAlertTriangle,
          component: Penalties,
          subMenu: [],
        },
        {
          title: 'Estatuto',
          path: '/estatuto',
          icon: GoLaw,
          component: Estatuto,
          subMenu: [],
        },
      ],
    }
  ] as MenuProps[],
  caster: [
    {
      title: 'Jogos',
      path: '/',
      icon: FiAward,
      component: Classificacao,
      subMenu: [
        {
          title: 'Atleticas',
          path: '/atleticas',
          icon: FiShield,
          component: Atleticas,
          subMenu: [],
        },
      ],
    },
    {
      title: 'Logística',
      path: '/',
      icon: FiAward,
      component: Classificacao,
      subMenu: [
        {
          title: 'Programação',
          path: '/schedule',
          icon: FiCalendar,
          component: Schedule,
          subMenu: [],
        },
        {
          title: 'Praças esportivas',
          path: '/places',
          icon: FiMapPin,
          component: Places,
          subMenu: [],
        },
      ]
    },
    {
      title: 'Modalidades',
      path: '/qualifiers',
      icon: MdSportsHandball,
      component: Qualifier,
      subMenu: [
        {
          title: 'Com chaveamento',
          path: '/qualifiers',
          component: Qualifier,
          icon: MdSportsHandball,
        },
        {
          title: 'Sem chaveamento',
          path: '/individuais',
          component: Individuals,
          icon: MdDirectionsRun,
        },
      ],
    },
  ] as MenuProps[],
  app: [
    {
      title: 'Aplicativo',
      path: '/caster',
      icon: FiPhone,
      component: Caster,
      subMenu: [
        {
          title: 'Casters',
          path: '/caster',
          icon: FiUsers,
          component: Caster,
        },
        {
          title: 'Festas',
          path: '/party',
          icon: GiBeerStein,
          component: PageParty
        },
        {
          title: 'Notificação',
          path: '/news',
          icon: MdNotifications,
          component: PageNews
        },
        {
          title: 'Atletas',
          path: '/players',
          icon: FiUserPlus,
          component: PageAtletas
        }
      ],
    },
  ] as MenuProps[],
};

export const routes = [
  {
    title: 'Classificação',
    path: '/',
    icon: FiAward,
    component: Classificacao,
    subMenu: [],
  },
  {
    title: 'Tabelas',
    path: '/dashboard',
    icon: BsTable,
    component: Dashboard,
    subMenu: [],
  },
  {
    title: 'C.O.',
    path: '/co',
    icon: FiUsers,
    component: Org,
    subMenu: [],
  },
  {
    title: 'Atleticas',
    path: '/atleticas',
    icon: FiShield,
    component: Atleticas,
    subMenu: [],
  },
  {
    title: 'Programação',
    path: '/schedule',
    icon: FiCalendar,
    component: Schedule,
    subMenu: [],
  },
  {
    title: 'Horarios',
    path: '/calendar',
    icon: FiCalendar,
    component: Calendar,
    subMenu: [],
  },
  {
    title: 'Config',
    path: '/schedule-config',
    icon: FiSettings,
    component: SchedulerConfig,
    subMenu: [],
  },
  {
    title: 'Modalidades',
    path: '/qualifiers',
    icon: MdSportsHandball,
    component: Qualifier,
    subMenu: [
      {
        title: 'Com chaveamento',
        path: '/qualifiers',
        component: Qualifier,
        icon: MdSportsHandball,
      },
      {
        title: 'Sem chaveamento',
        path: '/individuais',
        component: Individuals,
        icon: MdDirectionsRun,
      },
    ],
  },
  {
    title: 'Alojamento',
    path: '/aloja',
    icon: MdSportsHandball,
    component: PageAccommodation,
    subMenu: [
      {
        title: 'Geral',
        path: '/aloja',
        component: PageAccommodation,
        icon: FaCampground,
      },
      {
        title: 'Mapa',
        path: '/places?filter=home',
        icon: FiMapPin,
        component: Places,
        subMenu: [],
      },
    ],
  },
  {
    title: 'Penalidades',
    path: '/penalidades',
    icon: FiAlertTriangle,
    component: Penalties,
    subMenu: [],
  },
  {
    title: 'Estatuto',
    path: '/estatuto',
    icon: GoLaw,
    component: Estatuto,
    subMenu: [],
  },
  {
    title: 'Praças esportivas',
    path: '/places',
    icon: FiMapPin,
    component: Places,
    subMenu: [],
  },
  {
    title: 'Aplicativo',
    path: '/caster',
    icon: FiPhone,
    component: Caster,
    subMenu: [
      {
        title: 'Casters',
        path: '/caster',
        icon: FiUsers,
        component: Caster,
      },
      {
        title: 'Festas',
        path: '/party',
        icon: GiBeerStein,
        component: PageParty
      },
      {
        title: 'Notificação',
        path: '/news',
        icon: MdNotifications,
        component: PageNews
      },
      {
        title: 'Atletas',
        path: '/players',
        icon: FiUserPlus,
        component: PageAtletas
      }
    ],
  },
] as MenuProps[]

export default globalMenu;
