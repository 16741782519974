import { atom } from 'recoil';
import { IAccommodation, IAdmin, IAtletica, ICaster, IFine, IIndividuals, INotification, IPenalty, IPlace, IPlayer, IQualifier } from '../types';

const keys = {
    ADMINS: 'ADMINS',
    CASTER: 'CASTER',
    ATLETICAS: 'ATLETICAS',
    PENALTIES: 'PENALTIES',
    FINES: 'FINES',
    QUALIFIER: 'QUALIFIER',
    PLAYERS: 'PLAYERS',
    PLACES: 'PLACES',
    INDIVIDUALS: 'INDIVIDUALS',
    NOTIFICATIONS: 'NOTIFICATIONS',
    ACCOMMODATION: 'ACCOMMODATION',
}

export const Admins = atom<IAdmin[]>({
    key: keys.ADMINS,
    default: [] as IAdmin[]
})

export const Caster = atom<ICaster[]>({
    key: keys.CASTER,
    default: [] as ICaster[]
})

export const Atleticas = atom<IAtletica[]>({
    key: keys.ATLETICAS,
    default: [] as IAtletica[]
})

export const Players = atom<IPlayer[]>({
    key: keys.PLAYERS,
    default: [] as IPlayer[]
})

export const Penalties = atom<IPenalty[]>({
    key: keys.PENALTIES,
    default: [] as IPenalty[]
})

export const Fines = atom<IFine[]>({
    key: keys.FINES,
    default: [] as IFine[]
})

export const Accommodation = atom<IAccommodation[]>({
    key: keys.ACCOMMODATION,
    default: [] as IAccommodation[]
})

export const Qualifier = atom<IQualifier[]>({
    key: keys.QUALIFIER,
    default: [] as IQualifier[]
})

export const Places = atom<IPlace[]>({
    key: keys.PLACES,
    default: [] as IPlace[]
})

export const Individuals = atom<IIndividuals[]>({
    key: keys.INDIVIDUALS,
    default: [] as IIndividuals[]
})

export const Notification = atom<INotification[]>({
    key: keys.NOTIFICATIONS,
    default: [] as INotification[]
})