import { useCallback, useMemo, useState } from 'react';
import { FiEdit, FiPlus, FiTrash2 } from 'react-icons/fi';
import { Column } from 'react-table';
import { ModalParty } from './components/ModalParty';
import { ModalPartyEdit } from './components/ModalPartyEdit';
import { ModalWaring } from '../../components/ModalWaring';
import Table from '../../components/Table';
import { useAuth } from '../../hooks/auth';
import { IParty } from '../../types';

import { Container, IconContainer, Header, AddNew } from './styles';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import api from '../../services/api';
import { MoonLoader } from 'react-spinners';

function PageParty() {
  const widthScreen = window.innerWidth;
  const { admin } = useAuth();
  const isAtletica = useMemo(
    () => admin?.responsibility === 'atletica',
    [admin],
  );
  const [showPartyModal, setShowPartyModal] = useState(false);
  const [showPartyModalEdit, setShowPartyModalEdit] = useState(false);
  const [showPartyModalDelete, setShowPartyModalDelete] = useState(false);
  const [editParty, setEditParty] = useState({} as IParty);
  const [deleteParty, setDeleteParty] = useState('');

  const {
    data: parties,
    isLoading,
    refetch
  } = useQuery<IParty[]>(['parties'], async () => {
    const { data } = await api.get('/party');
    return data;
  }, {})
  
  const toggleShowModalOpenEdit = useCallback(() => {
    setShowPartyModalEdit(!showPartyModalEdit);
  }, [showPartyModalEdit]);

  const toggleShowModalOpenDelete = useCallback(() => {
    setShowPartyModalDelete(!showPartyModalDelete);
  }, [showPartyModalDelete]);

  const handleEditParty = useCallback(
    (partyEdit: IParty) => {
      toggleShowModalOpenEdit();
      setEditParty(partyEdit);
    },
    [toggleShowModalOpenEdit],
  );

  const handleDeleteParty = useCallback(
    (partyId: string) => {
      toggleShowModalOpenDelete();
      setDeleteParty(partyId);
    },
    [toggleShowModalOpenDelete],
  );

  const delParty = useCallback(async () => {
    await api.delete(`/party/${deleteParty}`);
    toggleShowModalOpenDelete();
    refetch();
  }, [deleteParty, refetch, toggleShowModalOpenDelete]);

  const columns: Column<any>[] = useMemo(
    () => [
            {
              Header: 'Título',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {row.original.title}
                  </div>
                );
              },
            },
            {
              Header: 'Data',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {format(new Date(row.original.date), 'dd/MM/yyyy')}
                  </div>
                );
              },
            },
            {
              Header: 'Opções',
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IconContainer
                      type="edit"
                      onClick={() => handleEditParty(row.original)}
                    >
                      <FiEdit />
                      {widthScreen <= 820 && 'Editar'}
                    </IconContainer>
                    <IconContainer
                      type="del"
                      onClick={() => handleDeleteParty(row.original.id)}
                    >
                      <FiTrash2 />
                      {widthScreen <= 820 && 'Deletar'}
                    </IconContainer>
                  </div>
                );
              },
            },
          ],
    [handleEditParty, handleDeleteParty, widthScreen],
  );

  const toggleShowModalOpen = useCallback(() => {
    setShowPartyModal(!showPartyModal);
  }, [showPartyModal]);

  return (
    <Container>
      <Header>
        <h1>Festas</h1>
        {!isAtletica && (
          <AddNew onClick={toggleShowModalOpen}>
            <FiPlus />
            New
          </AddNew>
        )}
      </Header>
      {!isLoading ? (
        <Table columns={columns} data={parties || []} />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            alignItems: 'center',
          }}
        >
          <MoonLoader color="#3B1467" loading speedMultiplier={0.75} />
          Carregando...
        </div>
      )}

      <ModalParty
        showModalOpen={showPartyModal}
        toggleShowModalOpen={toggleShowModalOpen}
        refetch={refetch}
      />
      <ModalPartyEdit
        showModalOpen={showPartyModalEdit}
        toggleShowModalOpen={toggleShowModalOpenEdit}
        party={editParty}
        refetch={refetch}
      />
      <ModalWaring
        showModalOpen={showPartyModalDelete}
        toggleShowModalOpen={toggleShowModalOpenDelete}
        title="Deletar festar"
        message="Tem certeza que deseja deletar essa festa?"
        textLeftButton="Cancelar"
        textRightButton="Deletar"
        handleLeftButton={toggleShowModalOpenDelete}
        handleRightButton={delParty}
      />
    </Container>
  );
}

export default PageParty;
