import { Form } from '@unform/web';
import { useCallback, useRef } from 'react';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';
import { useData } from '../../../../hooks/context';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import {
    Container,
    Content,
    Header,
} from './styles'
import { FormHandles } from '@unform/core';
import FormContainer from '../../../../components/FormContainer';

interface ModalCasterProps {
    showModalOpen: boolean;
    toggleShowModalOpen: () => void;
}

export function ModalCaster({ showModalOpen, toggleShowModalOpen }: ModalCasterProps) {

    const formRef = useRef<FormHandles>(null);
    const { useCaster, loading } = useData();

    const handleSubmit = useCallback(async (data: any) => {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            email: Yup.string().required('Email obrigatório').email('Insira um email válido'),
            password: Yup.string().min(6, 'No mínimo 6 digitos'),
            password_confirmation: Yup.string()
                .min(6, 'No mínimo 6 digitos')
                .oneOf([Yup.ref('password'), undefined], 'Confirmação incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        useCaster.addCaster({
            ...data,
        });
        formRef.current?.reset();
        toggleShowModalOpen();
    }, [toggleShowModalOpen, useCaster])

    return (
        <Modal
            isOpen={showModalOpen}
            setIsOpen={toggleShowModalOpen}
        >
            <Container>
                <Header>
                    <h2>Caster</h2>
                    <div onClick={toggleShowModalOpen}>
                        <FiX />
                    </div>
                </Header>
                <Content>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <Input
                            name="name"
                            placeholder='Nome'
                            label='Nome:'
                        />
                        <Input
                            name="email"
                            placeholder='E-mail'
                            label='E-mail:'
                        />
                        <FormContainer line="1fr 1fr">
                            <Input
                                name="password"
                                placeholder='Senha'
                                label='Senha:'
                            />
                            <Input
                                name="confirmation_password"
                                placeholder='Confirmação da Senha'
                                label='Confirmação da Senha:'
                            />
                        </FormContainer>
                        <div className='buttons'>
                            <Button
                                buttonStyle='primary'
                                theme='hollow'
                                onClick={toggleShowModalOpen}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                buttonStyle='primary'
                                theme='solid'
                                loading={loading}
                            >
                                Salvar
                            </Button>
                        </div>
                    </Form>
                </Content>
            </Container>
        </Modal>
    )
}