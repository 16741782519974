/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import React, {
    InputHTMLAttributes,
    useState,
    useCallback,
    ChangeEvent,
  } from 'react';
  import MoonLoader from 'react-spinners/MoonLoader';
  import { Container } from './styles';
  
  interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    containerStyle?: any;
    uploadFile: (picture: any) => Promise<any>;
  }
  
  const ImportData: React.FC<InputProps> = ({
    containerStyle = {},
    uploadFile,
    ...rest
  }) => {
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<File | null>(null);	
  
    const handleFile = useCallback(
      async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setLoading(true);
            setFile(e.target.files[0])
            uploadFile(e.target.files[0]).then(() => {
              setLoading(false);
            });
          } 
      },
      [uploadFile],
    );
  
    return (
      <Container
        style={containerStyle}
        isErrored={!!false}
        isFilled={false}
        isFocused={false}
      >
        <label htmlFor="photo">
          <input onChange={handleFile} type="file" id="photo" {...rest} />
        </label>
        {loading ? (
          <div className="loading">
            <MoonLoader color="#3B1467" loading />
          </div>
        ) : (
          <div className='content'>{
            file ?
              file.name :
              "Selecione o arquivo .csv"}</div>
        )}
      </Container>
    );
  };
  
  export default ImportData;