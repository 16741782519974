import { SetterOrUpdater } from "recoil";
import api from "../../services/api";
import { IAtletica, ICategory, IIndividuals,  IPlace } from "../../types";
import { produce } from 'immer'

export async function auxUpdateIndividual(
  individual: IIndividuals,
  setIndividual: SetterOrUpdater<IIndividuals[]>,
  state: IIndividuals[],
  atleticas: IAtletica[],
) {
  try {
    api.put(`/individual/${individual.id}`, individual).then(response => {
      const index = state.findIndex(item => item.id === individual.id);
      const updatedIndividual = state.slice();
      const allAtleticas = response.data?.atletica_ids?.map((aaa_id: string) => {
        const aaa = atleticas.find(item => item.id === aaa_id);
        return aaa || {} as IAtletica
      })
      const newIndividual = {
        ...individual,
        atleticas: allAtleticas,
      }
      updatedIndividual[index] = newIndividual;
      setIndividual(updatedIndividual);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxRemoveIndividual(individual_id: string, setIndividual: SetterOrUpdater<IIndividuals[]>, state: IIndividuals[]) {
  try {
    api.delete(`/individual/${individual_id}`).then(response => {
      const filteredIndividual = state.filter(individual => individual.id !== individual_id)
      setIndividual(filteredIndividual);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxAddIndividual(
  individual: Omit<IIndividuals, 'id' | 'atleticas' | 'place'>,
  setIndividual: SetterOrUpdater<IIndividuals[]>,
  state: IIndividuals[],
  atleticas: IAtletica[],
  places: IPlace[],
) {
  try {
    api.post('/individual', individual).then(response => {
      const allAtleticas = response.data.atletica_ids.map((aaa_id: string) => {
        const aaa = atleticas.find(item => item.id === aaa_id);
        return aaa || {} as IAtletica
      })
      const findPlace = places.find(place => place.id === response.data.place_id);
      const newIndividual: IIndividuals = {
        ...response.data,
        atleticas: allAtleticas,
        place: findPlace ? [findPlace] : []
      }
      setIndividual([newIndividual, ...state]);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxAddCategory(
  category: Omit<ICategory, 'id' | 'classification' | 'matches'>,
  setIndividual: SetterOrUpdater<IIndividuals[]>,
  state: IIndividuals[],
) {
  try {
    api.post('/category', category).then(response => {

      const updatedIndividuals = produce(state, draft => {
        const findIndex = state.findIndex(item => item.id === category.individual_id);
        if(findIndex < 0){
          return draft
        }
        const categories_ids = draft[findIndex].categories_id || []
        const categories = draft[findIndex].categories || []
        draft[findIndex].categories_id = [...categories_ids, response.data.id]
        draft[findIndex].categories = [...categories, response.data]
        return draft
      })
      setIndividual(updatedIndividuals);
    })
  } catch (e) {
    console.log(e)
  }
}

export async function auxUpdateCategory(
  category: ICategory,
  setIndividual: SetterOrUpdater<IIndividuals[]>,
  state: IIndividuals[],
) {
  try {
    api.put(`/category/${category.id}`, category).then(response => {

      const updatedIndividuals = produce(state, draft => {
        const findIndex = state.findIndex(item => item.id === category.individual_id);
        if(findIndex < 0){
          return draft
        }
        const categoriesTemp = draft[findIndex].categories?.slice() || [];
        const findIndexCategory = categoriesTemp.findIndex(cat => cat.id === category.id)
        if(findIndexCategory < 0){
          return draft
        }
        categoriesTemp[findIndexCategory] = response.data
        draft[findIndex].categories = categoriesTemp
        return draft
      })
      setIndividual(updatedIndividuals);
    })
  } catch (e) {
    console.log(e)
  }
}