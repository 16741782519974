import styled from 'styled-components';

interface ColorProps {
  color: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 80vh;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 2rem 0 3rem;
  height: 100%;

  > form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    gap: 1rem;

    > .buttons {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-top: 1.5rem;
    }
  }
`;

export const Color = styled.div<ColorProps>`
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  border: 1px solid #000;
  cursor: pointer;
  background-color: ${(props) => props.color};
`;

export const ModalColorPicker = styled.div`
  position: absolute;
  background-color: #DBDBDB;
  padding: 0.5rem;
  border-radius: 0.5rem;
  z-index: 999;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > div {
    cursor: pointer;
  }
`;
